import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import {es} from 'date-fns/locale'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InfoDialog from "elements/InfoDialog";
import { ProjectConfig } from "Global";
import axios from "axios";
import { esES } from "@mui/x-date-pickers";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import { useSnackbar } from "notistack";

const defaultValues = {
    date: null,
    dia: null,
    semana: '1',
    tiempo: "tiempo_ordinario",
    lecturas: "lectura_año_1",
}

export default function ConfigurarAnioLiturgicoForm( props: any ) {
    const {
        open,
        setOpen,
        loadData
    } = props;

    const [openAlert, setOpenAlert] = useState(false);
    const [formValues, setFormValues] = useState<any>(defaultValues);
    const [alertMessage, setAlertMessage] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const checkForm = () => {
        let errors = false;

        if (formValues.tiempo === null || formValues.tiempo === '') {
            enqueueSnackbar('Debe seleccionar un tiempo', {variant: 'error'});
            errors = true;
        }

        if (formValues.semana === null || formValues.semana === '') {
            enqueueSnackbar('Debe seleccionar una semana', {variant: 'error'});
            errors = true;
        }

        if (formValues.date === null || formValues.date === '') {
            enqueueSnackbar('Debe seleccionar una fecha', {variant: 'error'});
            errors = true;
        }

        if (formValues.lecturas === null || formValues.lecturas === '') {
            enqueueSnackbar('Debe seleccionar un tipo de lecturas', {variant: 'error'});
            errors = true;
        }

        return errors;
    }

    const handleSubmit = () => {

        console.log("sent",formValues)

        axios.post(ProjectConfig.api_url,formValues,{
            params: {
                request: 'add_ano_liturgico',
            },
        })
        .then((response) => {
            console.log('submit form',response.data);
            if (response.data.status === "success") {
                setOpen(false);
                loadData();
            }
            if (response.data.status === "error") {
                setAlertMessage(response.data.message);
                setOpenAlert(true);
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
        });
    }

    const getDay = (date: string) => {
        const day =  new Date(formValues.date.replaceAll('-','/')).getDay();

        switch (day) {
            case 0: return "Domingo";
            case 1: return "Lunes";
            case 2: return "Martes";
            case 3: return "Miércoles";
            case 4: return "Jueves";
            case 5: return "Viernes";
            case 6: return "Sábado";
            default: return "";
        }
    }

    
    return (
        <>
            <Dialog className='filtersDialog' open={open} onClose={() => setOpen(false)}>
                <DialogContent className='forced_box_editor' style={{minWidth: '250px'}}>
                    <h2>Configurar Año Liturgico</h2>
                    
                    <div className="filters_container">
                        <LocalizationProvider dateAdapter={AdapterMoment}
                            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}  
                        >
                            <div className="filter_group">
                                <MobileDatePicker
                                label="Fecha"
                                inputFormat="DD/MM/YYYY"
                                value={formValues.date}
                                
                                InputProps={{
                                    endAdornment: (
                                    <EventIcon />
                                    ),
                                }}
                                showToolbar={false}
                                closeOnSelect
                                onChange={(newValue) => {
                                    setFormValues({
                                        ...formValues,
                                        ["date"]: (newValue).format('YYYY-MM-DD'),
                                        ["dia"]: (newValue).format('d'),
                                        
                                    });
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                />

                                <Typography variant="body2" sx={{alignSelf:"end"}}>
                                    {formValues.date ? "Día: " + getDay(formValues.date): "Seleccione una fecha"}
                                </Typography>
                            </div>
                        </LocalizationProvider>

                        

                        <TextField
                            id="semana"
                            name="semana"
                            label="Semana"
                            type="text"
                            required
                            select
                            fullWidth
                            value={formValues.semana}
                            onChange={(e) => setFormValues({...formValues, semana: e.target.value})}
                            >
                            {Array.from({length: 35}).map( (item,index) => (
                                <MenuItem key={index} value={index+1}>{index+1}</MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id="tiempo"
                            name="tiempo"
                            label="Tiempo"
                            type="text"
                            required
                            select
                            fullWidth
                            value={formValues.tiempo}
                            onChange={(e) => setFormValues({...formValues, tiempo: e.target.value})}
                        >
                            <MenuItem value="tiempo_ordinario">Tiempo ordinario</MenuItem>
                            <MenuItem value="cuaresma">Cuaresma</MenuItem>
                            <MenuItem value="semana_santa">Semana Santa</MenuItem>
                            <MenuItem value="pascua">Pascua</MenuItem>
                            <MenuItem value="adviento">Adviento</MenuItem>
                            <MenuItem value="navidad">Navidad</MenuItem>
                        </TextField>

                        <TextField
                            id="lecturas"
                            name="lecturas"
                            label="Lecturas"
                            type="text"
                            required
                            select
                            fullWidth
                            value={formValues.lecturas}
                            onChange={(e) => setFormValues({...formValues, lecturas: e.target.value})}
                        >
                            <MenuItem value="lecturas_ano_1">Lectura Año 1</MenuItem>
                            <MenuItem value="lecturas_ano_2">Lectura Año 1</MenuItem>
                            <MenuItem value="lecturas_dominical_a">Lectura Dominical Año A</MenuItem>
                            <MenuItem value="lecturas_dominical_b">Lectura Dominical Año B</MenuItem>
                            <MenuItem value="lecturas_dominical_c">Lectura Dominical Año C</MenuItem>
                        </TextField>
                    </div>

                    <div className ='buttons'>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (!checkForm()) {
                                    handleSubmit()
                                }
                            }}
                        >
                            Crear
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <InfoDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle={alertMessage}
            />
        </>
    );
}