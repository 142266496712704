import React, { useEffect, useState } from 'react';
import {
    Button,
    Chip,
    CircularProgress,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
import axios from 'axios';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import AlertDialog from 'elements/AlertDialog';
import Box from 'elements/Box';
import { ProjectConfig } from 'Global';
import Filters from 'elements/Filters/Filters';
import FiltersApplied from 'elements/Filters/FiltersApplied';

import 'assets/css/main.css';
import ConfigurarAnioLiturgicoForm from './ConfigurarAnoLiturgicoForm';



export default function ConfigurarAnioLiturgicoList() {
    /*================================================
    FILTERS
    =================================================*/
    const filters = [
        {type: "date", label: "Fecha", name: "date"},
        {type: "select", label: "Tiempo", name: "tiempo", options: [{label: 'Tiempo ordinario', value: 'tiempo_ordinario'}, {label: 'Cuaresma', value: 'cuaresma'}, {label: 'Semana Santa', value: 'semana_santa'}, {label: 'Pascua', value: 'pascua'}, {label: 'Adviento', value: 'adviento'}, {label: 'Navidad', value: 'navidad'},]},
        {type: "select", label: "Día", name: "dia", options: [{label: 'Domingo', value: '0'}, {label: 'Lunes', value: '1'}, {label: 'Martes', value: '2'}, {label: 'Miércoles', value: '3'}, {label: 'Jueves', value: '4'}, {label: 'Viernes', value: '5'}, {label: 'Sábado', value: '6'},]},
        {type: "select", label: "Semana", name: "semana", options: Array.from({length: 35}, (e, i) => Object({label: `Semana ${i+1}`, value: (i+1).toString()}))}
    ]
    const [applyFilters, setApplyFilters] = useState({});
    const [filtersValues, setFiltersValues] = useState<any>({});
    const [openFilters, setOpenFilters] = useState(false);

    const setFilters = (tempFilters: any) => {
        setFiltersValues(tempFilters);
    }
    /*================================================= 
    ==================================================*/

    // HOOKS
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') || 1;

    const navigate = useNavigate();

    const [openAlert, setOpenAlert] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<any[]>([]);
    const [total_pages, setTotalPages] = useState(0);
    const [activeItem, setActiveItem] = useState<any>(null);
    

    useEffect (() => {
        setLoading(false);
        console.log(filtersValues);
        console.log(applyFilters);
        loadData();
    }, [filtersValues, page]);


    // FUNCTIONS
    const loadData = () => {
        setLoading(true);
        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'textos_liturgicos_list_dates',
                params: {
                    page: page,
                    filters: filtersValues,
                },
            }
        })
        .then((response) => {
            console.log('load list', response.data);
            setList(response.data.list);
            setTotalPages(response.data.total_pages);
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    // HANDLERS

    function handleDelete(): void {
    
        if (!activeItem) return;
        setLoading(true);
        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'delete_ano_liturgico_date',
                texto_liturgico_date_id: activeItem.texto_liturgico_date_id,
            }
        })
        .then((response) => {
            console.log(response.data);
            loadData();
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    // RENDER
    return (
        <>
            <div id='textosLiturgicosList'>
                <div className='buttons_top'>
                    <Filters
                        open={openFilters}
                        setOpen={setOpenFilters}
                        filters={filters}
                        setFilters={setFilters}
                        setApplyFilters={setApplyFilters}
                    />
                    <Button variant="contained" onClick={()=>setOpenNew(true)}>
                        Nuevo
                    </Button>
                </div>
                
                <div id="textos_liturgicos_list_container" className="flex_container">
                    <Box id='textos_liturgicos_list' title="Configurar Año Litúrgico" subtitle="Lista"  >
                        <FiltersApplied
                            applyFilters={applyFilters}
                            setApplyFilters={setApplyFilters}
                            filtersValues={filtersValues}
                            setFiltersValues={setFiltersValues}
                        />
                        {loading && <div id='loader'><CircularProgress /></div>}
                        <Table stickyHeader aria-label="sticky table"> 
                            <TableHead>
                                <TableRow>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Color</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {list.map((list_item,i) => (
                                <TableRow 
                                    key={i}
                                    hover
                                    role="checkbox"
                                >
                                    <TableCell>    
                                        {list_item.date}
                                    </TableCell>

                                    <TableCell>    
                                        {list_item.name}
                                    </TableCell>
                                    
                                    <TableCell>
                                        <Chip
                                            id='textos-liturgicos-chip'
                                            label={list_item.color} 
                                            variant="outlined"
                                            sx={{borderColor: list_item.color_hex, color: list_item.color_hex,}}
                                        />
                                    </TableCell>

                                    <TableCell> 
                                        <IconButton
                                            aria-label="delete"
                                            color="primary"
                                            onClick={() => {
                                                setActiveItem(list_item);
                                                setOpenAlert(true);
                                            }}
                                        >
                                            <DeleteOutlineOutlined />
                                        </IconButton>

                                        <IconButton
                                            aria-label="edit"
                                            color="primary"
                                            onClick={() => {
                                                navigate('/admin/textos_liturgicos/edit/' + list_item.texto_liturgico_id)
                                            }}
                                        >
                                            <EditOutlined />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        
                        {total_pages > 1 &&
                        <Pagination
                            count={total_pages}
                            page={Number(page)}
                            onChange={(e, value) => {
                                navigate(`?page=${value}`)
                            }}
                            color="primary"
                            sx={{mt: "20px", alignSelf: "center"}}
                        />
                        }
                    </Box>
                </div>
            </div>
            
            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                send={handleDelete}
            />

            <ConfigurarAnioLiturgicoForm
                open={openNew}
                setOpen={setOpenNew}
                loadData={loadData}
            />
        </>
    );
}