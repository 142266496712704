import { Button, IconButton } from '@mui/material';
import { useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

interface Props {
    id?:string,
    className?:string,
    title?:string,
    subtitle?:string,
    children?: React.ReactNode;
}    

const Box = (props: Props) => {

  const className = props.className ? " "+props.className : "";
  const id = props.id ? props.id : "";


  const box_header = props.title? <div className='box_header'>
    <h2 className='card_title'>{props.title}</h2>
    <span className='card_subtitle'>{props.subtitle}</span>
  </div> : null;
  
  

  return (
    
    <div id={id} className={"box"+className} >
      <div className="box_children">

        {box_header}

        <div className="box_content">
          {props.children}
        </div>

      </div>
    </div>
  );
};



export default Box;