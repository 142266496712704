import { Button, IconButton, TextareaAutosize, TextField, Select, MenuItem } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import App from "elements/HtmlEditor/App";
import { esES } from "@mui/x-date-pickers";
import "moment/locale/es";
import { useSnackbar, withSnackbar } from "notistack";
import moment from "moment";



export default withSnackbar(
  function FiestasEditor(props: any) {


    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    /*=======================================
      GET PROJECT ID AND ESTIMATE ID FROM URL
    =======================================*/
    let { fiesta_id } = useParams();
    /*=======================================
    =======================================*/


    const navigate = useNavigate();
    const defaultValues: any = {
      fiesta_id: fiesta_id ? fiesta_id : "null",
      titulo: "",
      image: "",
      date_data: null,
      bioHTML_primeras_visperas: "",
      bioJSON_primeras_visperas: null,
      bioHTML_oficio_lectura: "",
      bioJSON_oficio_lectura: null,
      bioHTML_laudes: "",
      bioJSON_laudes: null,
      bioHTML_horas_intermedias: "",
      bioJSON_horas_intermedias: null,
      bioHTML_visperas: "",
      bioJSON_visperas: null,
      bioHTML_completas: "",
      bioJSON_completas: null,
      bioHTML_lecturas: "",
      bioJSON_lecturas: null
    };

    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState(defaultValues)
    const [files, setFiles] = useState<any>([]);




    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
      const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: value,
      });
    };




    /*=======================================
      TEXT EDITOR
    =======================================*/
 
    const [initialContent1, setInitialContent1] = useState<any>(null);
    const [initialContent2, setInitialContent2] = useState<any>(null);
    const [initialContent3, setInitialContent3] = useState<any>(null);
    const [initialContent4, setInitialContent4] = useState<any>(null);
    const [initialContent5, setInitialContent5] = useState<any>(null);
    const [initialContent6, setInitialContent6] = useState<any>(null);
    const [initialContent7, setInitialContent7] = useState<any>(null);






    const handleEditorChangeVisperas = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        bioHTML_visperas: htmlContent,
        bioJSON_visperas: jsonContent
      });
    }

    const handleEditorChangeOficio_lectura = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        bioHTML_oficio_lectura: htmlContent,
        bioJSON_oficio_lectura: jsonContent
      });
    }

    const handleEditorChangeLaudes = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        bioHTML_laudes: htmlContent,
        bioJSON_laudes: jsonContent
      });
    }

    const handleEditorChangeHoras_intermedias = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        bioHTML_horas_intermedias: htmlContent,
        bioJSON_horas_intermedias: jsonContent
      });
    }

    const handleEditorChangeCompletas = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        bioHTML_completas: htmlContent,
        bioJSON_completas: jsonContent
      });
    }

    const handleEditorChangeLecturas = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        bioHTML_lecturas: htmlContent,
        bioJSON_lecturas: jsonContent
      });
    }
    const handleEditorChangePrimeras_visperas = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        bioHTML_primeras_visperas: htmlContent,
        bioJSON_primeras_visperas: jsonContent
      });
    }
    /*=======================================
    =======================================*/

    const [colores, setColores] = useState<any[]>([]); 
    


    /*=======================================
      GET DETAILS IF EDITING
    =======================================*/
    useEffect(() => {
      axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "all_colores",
        },
      })
      .then((response) => {
        // console.log(response.data)
        const coloresData = JSON.parse(response.data.colores);
        setColores(coloresData);
        // console.log(coloresData)
        // console.log(colores)
      })
      .catch((err) => {
        console.log(err.data);
      });

      if (fiesta_id) {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "fiesta_details",
              id: fiesta_id,
            },
          })
          .then((response) => {

            setFormValues(response.data);
            
            setInitialContent1(response.data.bioJSON_primeras_visperas);
            setInitialContent2(response.data.bioJSON_oficio_lectura);
            setInitialContent3(response.data.bioJSON_laudes);
            setInitialContent4(response.data.bioJSON_horas_intermedias);
            setInitialContent5(response.data.bioJSON_visperas);
            setInitialContent6(response.data.bioJSON_completas);
            setInitialContent7(response.data.bioJSON_lecturas);

            
           
          })
          .catch((err) => {
            console.log(err.data);
          });
      }else{
      
      }
    }, []);
    /*=======================================
    =======================================*/

    



    // //  lISTADO DE COLERES
    // const colorsList = [
    //   { value: "red", label: "Rojo" },
    //   { value: "green", label: "Verde" },
    //   { value: "blue", label: "Azul" },
    //   // Agrega más colores según sea necesario
    // ];



    /*=======================================
      SUBMIT
    =======================================*/
    const [progress, setProgress] = useState(0);

    const checkForm = (): boolean => {

      let errors = false;

      if (formValues.titulo == "") {
        enqueueSnackbar("El nombre es obligatorio", { variant: "error", });
        errors = true;
      }
      // if (formValues.date == null) {
      //   enqueueSnackbar("La fecha es obligatoria", { variant: "error", });
      //   errors = true;
      // }

      return errors;
    }

    const handleSubmit = () => {
      // console.log(formValues.date);
      //FILES NEEDS TO BE UPLOADED USING FORMDATA, APART FROM THE OTHER FIELDS
      const formData = new FormData();
      formData.append("files", files);
      formData.append("formValues", JSON.stringify(formValues));

      //AXIOS
      axios
        .post(ProjectConfig.api_url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );

          },
          params: {
            request: "add_edit_fiesta",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data) {
            navigate("/admin/fiestas");
          } else {
            enqueueSnackbar("Error al guardar", { variant: "error" });
          }

        })
        .catch((err) => {
          console.log(err.data);
        });


    }
    /*====================================
    =====================================*/


    const MIN_DATE = moment().subtract(350, 'years');




    return (
      <div id="userForm">
        <div className="userForm_right">
          <Box id="fiesta_editor" className="box_editor" title="Fiesta" subtitle="Editar"  >
            <div className='input_group'>
              <div className='input_group'>
                <div className='input_row'>

                  {/* UPLOAD button with preview */}
                  <div className="drawerImagesContainer">

                    <div className="drawerImagesContainerDiv"
                      onDrop={(e) => {
                        e.preventDefault();
                        // UPDATE FILE
                        const files = e.dataTransfer.files;
                        setFiles(files[0]);
                        const fileArray = Array.from(files).map((file: any) => URL.createObjectURL(file));
                        setFormValues({
                          ...formValues,
                          image: fileArray[0],
                        });
                        e.currentTarget.classList.remove("dropzone_active");
                      }
                      }

                      onDragOver={
                        (e) => {
                          e.preventDefault();
                          e.currentTarget.classList.add("dropzone_active");
                        }

                      }
                      onDragLeave={
                        (e) => {
                          e.preventDefault();
                          e.currentTarget.classList.remove("dropzone_active");
                        }
                      }

                    >
                      {formValues.image && (
                        <img src={formValues.image} />
                      )}

                      <label id='dropFileDiv' className={formValues.image && ('showOnlyOnHover')}>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          className="addFile_button"
                          component="span"
                        >
                          <CameraAltIcon />
                          <input
                            type="file"
                            multiple
                            hidden
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files) {

                                // UPDATE FILE
                                setFiles(files[0]);
                                const fileArray = Array.from(files).map((file: any) => URL.createObjectURL(file));
                                setFormValues({
                                  ...formValues,
                                  image: fileArray[0],
                                });

                              }

                            }}
                          />

                        </IconButton>
                      </label>

                    </div>

                  </div>



                  <div className="inputColumn">
                    <TextField
                      id="titulo"
                      name="titulo"
                      label="Titulo"
                      type="text"
                      required
                      fullWidth
                      value={formValues.titulo}
                      onChange={handleInputChange}
                    />

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        label="Fecha"
                        inputFormat="DD/MM/YYYY"
                        minDate={MIN_DATE}
                        value={formValues.date_data}
                        InputProps={{
                          endAdornment: (
                            <EventIcon />
                          ),
                        }}
                        showToolbar={false}
                        closeOnSelect
                        onChange={(newValue) => {
                          setFormValues({
                            ...formValues,
                            ["date_data"]: moment(newValue).format('YYYY-MM-DD')
                          });
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>

              
                <>
                <label>Primeras vísperas</label>
                  <App
                    placeholder="Primeras vísperas"
                    handleEditorChange={handleEditorChangePrimeras_visperas}
                    content={initialContent1}
                  />
                  <label>Oficio de lectura</label>
                  <App
                    placeholder="Oficio de lectura"
                    handleEditorChange={handleEditorChangeOficio_lectura}
                    content={initialContent2}
                  />
                  <label>Laudes</label>
                  <App
                    placeholder="Laudes"
                    handleEditorChange={handleEditorChangeLaudes}
                    content={initialContent3}
                  />
                  <label>Horas intermedias</label>
                  <App
                    placeholder="Horas intermedias"
                    handleEditorChange={handleEditorChangeHoras_intermedias}
                    content={initialContent4}
                  />
                  <label>Vísperas</label>
                  <App
                    placeholder="Vísperas"
                    handleEditorChange={handleEditorChangeVisperas}
                    content={initialContent5}
                  />
                  <label>Completas</label>
                  <App
                    placeholder="Completas"
                    handleEditorChange={handleEditorChangeCompletas}
                    content={initialContent6}
                  />
                  <label>Lecturas</label>
                  <App
                    placeholder="Lecturas"
                    handleEditorChange={handleEditorChangeLecturas}
                    content={initialContent7}
                  />
                  
                </>
            

            </div>
            <div className='buttons'>
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancelar</Button>
              <Button
                onClick={() => {
                  if (!checkForm()) {
                    handleSubmit()
                  }
                }}
                variant="contained"
                className="button_1"
              >
                Guardar
              </Button>
            </div>
          </Box>
        </div>





      </div>

    );
  })