import {Button, IconButton, TextareaAutosize, TextField} from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import App from "elements/HtmlEditor/App";
import { useSnackbar } from "notistack";



 const MisasEditor = () => {

  
  /*=======================================
    GET SANTORAL ID FROM URL
  =======================================*/
  let {misa_id } = useParams();
  /*=======================================
  =======================================*/

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const defaultValues: any = {
    misa_id: misa_id? misa_id : "null",
    title: "",
    contentHTML: "",
    contentJSON: null,
    date : null
  };

  
  const [loading, setLoading] = useState(false); 
  const [formValues, setFormValues] = useState(defaultValues)
  const [files, setFiles] = useState<any>([]);




  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };




  /*=======================================
    TEXT EDITOR
  =======================================*/
  const [initialContent, setInitialContent] = useState<any>(null);

  const handleEditorChange = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        contentHTML: htmlContent,
        contentJSON: jsonContent
      });
  }
  /*=======================================
  =======================================*/




  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (misa_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "misas_details",
            id: misa_id,
          },
        })
        .then((response) => {
          console.log("formValues", response.data)
          setFormValues(response.data);
          setInitialContent(response.data.contentJSON);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/

  


  /*=======================================
    SUBMIT
  =======================================*/
  const [progress, setProgress] = useState(0);

  const checkForm = () => {

    let errors = false;

    if(formValues.title === ""){
      enqueueSnackbar("El título es obligatorio", { variant: "error" });
      errors = true;
    }

    if(formValues.date === null){
      enqueueSnackbar("La fecha es obligatoria", { variant: "error" });
      errors = true;
    }

    return errors;
  }

  const handleSubmit = () => {
    console.log(formValues);

    //AXIOS
    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "add_edit_misas",
          formValues: formValues,
        },
      })
      .then((response) => {
        console.log(response);
        navigate("/admin/misas");
      })
      .catch((err) => {
        console.log(err.data);
      });


  }
  /*====================================
  =====================================*/







  return (
      <div id="userForm">
        <div className="userForm_right">
          <Box id="misa_editor" className="box_editor" title="Misa" subtitle="Editar"  >


            <TextField 
              id="title"
              name="title"
              label="Título"
              type="text"
              required
              fullWidth
              value={formValues.title}
              onChange={handleInputChange}
            />

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                label="Fecha de la celebración"
                inputFormat="DD/MM/YYYY"
                value={formValues.date}
                InputProps={{
                  endAdornment: (
                    <EventIcon />
                  ),
                }}
                showToolbar={false}
                closeOnSelect
                onChange={(newValue) => {
                  setFormValues({
                    ...formValues,
                    ["date"]: (newValue).format('YYYY-MM-DD')
                  });
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>





            <App 
              handleEditorChange={handleEditorChange} content={initialContent} 
            />
                      

                
            <div className='buttons'>
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancelar</Button>
              <Button onClick={()=>{
                if (!checkForm()) {
                  handleSubmit()    
                }
              }}
              variant="contained"
              className="button_1"
            >
              Guardar
            </Button>
            </div>
          </Box>
        </div>

        
          


      </div>
    
  );
};


export default MisasEditor;