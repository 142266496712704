import {Button, IconButton, TextareaAutosize, TextField} from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import App from "elements/HtmlEditor/App";



 const NotificacionesEditor = () => {

  
  /*=======================================
    GET  ID FROM URL
  =======================================*/
  let {notificacion_id } = useParams();
  /*=======================================
  =======================================*/


  const navigate = useNavigate();
  const defaultValues: any = {
    notificacion_id: notificacion_id? notificacion_id : "null",
    title: "",
    content: "",
  };
  
  const [loading, setLoading] = useState(false); 
  const [formValues, setFormValues] = useState(defaultValues)
  const [files, setFiles] = useState<any>([]);




  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };







  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (notificacion_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "notificaciones_details",
            id: notificacion_id,
          },
        })
        .then((response) => {
          console.log("formValues", response.data)
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/

  


  /*=======================================
    SUBMIT
  =======================================*/
  const [progress, setProgress] = useState(0);



  const handleSubmit = () => {
    console.log(formValues);
    //FILES NEEDS TO BE UPLOADED USING FORMDATA, APART FROM THE OTHER FIELDS


    //AXIOS
    axios
      .post(ProjectConfig.api_url, null, {

        params: {
          request: "add_edit_notificaciones",
          formValues: formValues,
        },
      })
      .then((response) => {
        navigate("/admin/notificaciones/");
      })
      .catch((err) => {
        console.log(err.data);
      });


  }
  /*====================================
  =====================================*/







  return (
      <div id="userForm">
        <div className="userForm_right">
          <Box id="santoral_editor" className="box_editor" title="Notificaciones" subtitle="Editar"  >


                    
            <TextField 
              id="title"
              name="title"
              label="Título"
              type="text"
              required
              fullWidth
              value={formValues.title}
              onChange={handleInputChange}
            />

            <TextField 
              id="content"
              name="content"
              label="Contenido"
              type="text"
              required
              fullWidth
              multiline
              value={formValues.content}
              onChange={handleInputChange}
              sx={{ width: '500px' }}
            />

              

            <div className='buttons'>
              {!notificacion_id && 
                <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancelar</Button>
              }
              {!notificacion_id && 
              <Button onClick={handleSubmit} variant="contained" className="button_1">Enviar</Button>}

              {notificacion_id &&
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cerrar</Button>}

            </div>
          </Box>
        </div>

        
          


      </div>
    
  );
};


export default NotificacionesEditor;