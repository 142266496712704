import React, { useState, } from "react";

import { ArrowBackIosNew, Event, Tune, } from '@mui/icons-material';
import { Button, Dialog, DialogContent, IconButton, MenuItem, TextField } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider, MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import 'assets/css/main.css';

interface Props {
  open: boolean;
  setOpen: any;
  filters: Array<any>;
  setFilters: any;
  setApplyFilters: any;
}

export default function Filters(props: Props) {

  const [tempFilters, setTempFilters] = useState<any>({});
  const [tempAppliedFilters, setTempAppliedFilters] = useState<any>({});
  
  const handleTempFilters = (filter: any, value: any) => {
    if (value === null || value === undefined || value === "") {
      delete tempFilters[filter.name];
      setTempFilters({...tempFilters});
    } else {
      setTempFilters({...tempFilters, [filter.name]: value});
    }
  }

  const handleAppliedFilters = (filterName: any, value: any) => {
    if (value === null || value === undefined || value === "") {
      delete tempAppliedFilters[filterName];
      setTempAppliedFilters({...tempAppliedFilters});
    } else {
      setTempAppliedFilters({...tempAppliedFilters, [filterName]: value});
    }
  }


  //TODAY 
  const today: any = new Date();
  const [selectedDate, handleDateChange] = useState(today);

  
  
    
  return (
    <>
        <IconButton
              color="inherit"
              aria-label="menu"
              onClick={() => props.setOpen(true)}
            >
            <Tune />
        </IconButton>

        <Dialog className='filtersDialog' open={props.open} onClose={() => props.setOpen(false)}>
        <DialogContent className='forced_box_editor' style={{minWidth: '250px'}}>
            <h2>BUSQUEDA</h2>
            {props.filters && props.filters.map((filter, index) => (
                <div className="filter" key={index}>
                
                {filter.type === "text" && (
                    <TextField 
                    variant="outlined"
                    label={filter.label}
                    fullWidth
                    value={tempFilters[filter.name] || ""}
                    onChange={(e) => {
                      handleTempFilters(filter, e.target.value)
                      handleAppliedFilters(filter.name, e.target.value)
                    }}
                />
                )}
                {filter.type === "date" && (
                    
                <LocalizationProvider dateAdapter={AdapterMoment}>
                <StaticDatePicker className='filters_datePicker'
                    label={filter.label}
                    displayStaticWrapperAs="mobile"
                    value={tempFilters[filter.name] || new Date()}
                    showToolbar={false}
                    
                    
                    
                    onChange={(newValue) => {
                      if (newValue) {
                        handleTempFilters(filter, newValue.format('YYYY-MM-DD'));
                        handleAppliedFilters(filter.name, newValue.format('YYYY-MM-DD'));
                      }
                    handleDateChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>

    )}
                {filter.type === "select" && (
                    <TextField 
                        variant="outlined"
                        label={filter.label}
                        fullWidth
                        select
                        value={tempFilters[filter.name] || ""}
                        onChange={(e) => {
                            handleTempFilters(filter, e.target.value)
                            handleAppliedFilters(filter.name, filter.options.filter((option: any) => option.value === e.target.value)[0].label)
                        }}
                    >
                        {filter.options && filter.options.map((option: any, index: number) => (
                            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </TextField>
                )}  
            </div>
            ))}

            <div className ='buttons'>
            <Button
              variant="contained"
              onClick={() => {
                props.setFilters(tempFilters)
                props.setApplyFilters(tempAppliedFilters)
                props.setOpen(false)
              }}
            >
              Apply
            </Button>
            </div>
        </DialogContent>
        </Dialog>
    </>
  );
}