import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const AniversariosList = () => {

  const [loading, setLoading] = useState(false); 
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);
  const [order, setOrder] = useState(['name', 'asc']);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'aniversarios_list',
          filters: filtersValues,
          page: page,
          order:order
        }
      })
        .then((response) => {
          console.log("ul ",response.data); 
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList, order])
  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (id:number) => {
    setActiveItem(id)
    setOpenAlert(true)
  }

  const deleteItem = () => {
    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_aniversarios',
          id: activeItem,
        }
      })
  
      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1)
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }
  /*=========================================================*/
  
  const [openAlert, setOpenAlert] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);

  const [filtersValues, setFiltersValues] = useState<any>({});

  function handleInputChange(event:any) {
    const nuevoTexto = event.target.value;
    // setTextoEscrito(nuevoTexto);


    filtersValues.texto = event.target.value;

    setPage(1);
    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'aniversarios_list',
          filters: filtersValues,
          page: page,
          order:order,

        }
      })
      .then((response) => {
        setList(response.data.list)
        setTotalPages(response.data.total_pages)




      })
      .catch((err) => {
        console.log(err.data);
      })
    localStorage.setItem('page', page.toString())
  }
  
  const handleMouseEnter = () => {
    // Cambiar el cursor cuando el mouse entra en el título
    document.body.style.cursor = "pointer";
  };

  const handleMouseLeave = () => {
    // Restaurar el cursor cuando el mouse sale del título
    document.body.style.cursor = "default";
  };

  const [order_name, setOrder_name] = useState(true)
  const [order_fecha, setOrder_fecha] = useState(false)
  const [order_fecha_ono, setOrder_fecha_ono] = useState(false)
  const [order_fecha_pro, setOrder_fecha_pro] = useState(false)
  
  function name_order()
  {
    if(order[0] == 'name')
    {
      if(order[1] == 'asc'){
        setOrder(['name', 'desc']);
        flecha('titulo', true);
      }else if(order[1] == 'desc'){
        setOrder(['name', 'asc']);
        flecha('titulo', false);
      }
    }else{
      setOrder(['name', 'asc']);
      flecha('titulo', false);
    }
  }

  function fecha(peticion: number) {
    switch (peticion) {
      case 1:
        if (!order_fecha) {
          setOrder(['fecha_cumple', 'ASC']);
          setOrder_fecha(true);
          flecha('fecha_cum', true);
        } else {
          setOrder(['fecha_cumple', 'DESC']);
          setOrder_fecha(false);
          flecha('fecha_cum', false);
        }
        break;
  
      case 2:
        if (!order_fecha_ono) {
          setOrder(['fecha_ono', 'ASC']);
          setOrder_fecha_ono(true);
          flecha('fecha_ona', true);
        } else {
          setOrder(['fecha_ono', 'DESC']);
          setOrder_fecha_ono(false);
          flecha('fecha_ona', false);
        }
        break;
      case 3:
        if (!order_fecha_ono) {
          setOrder(['fecha_pro', 'ASC']);
          setOrder_fecha_ono(true);
          flecha('fecha_pro', true);
        } else {
          setOrder(['fecha_pro', 'DESC']);
          setOrder_fecha_ono(false);
          flecha('fecha_pro', false);
        }
        break;
      case 4:
        if (!order_fecha_ono) {
          setOrder(['fecha_ord', 'ASC']);
          setOrder_fecha_ono(true);
          flecha('fecha_ord', true);
        } else {
          setOrder(['fecha_ord', 'DESC']);
          setOrder_fecha_ono(false);
          flecha('fecha_ord', false);
        }
        break;
      case 5:
        if (!order_fecha_ono) {
          setOrder(['fecha_des', 'ASC']);
          setOrder_fecha_ono(true);
          flecha('fecha_def', true);
        } else {
          setOrder(['fecha_des', 'DESC']);
          setOrder_fecha_ono(false);
          flecha('fecha_def', false);
        }
        break;
      case 6:
        if (!order_fecha_ono) {
          setOrder(['fecha_cre', 'ASC']);
          setOrder_fecha_ono(true);
          flecha('creacion', true);
        } else {
          setOrder(['fecha_cre', 'DESC']);
          setOrder_fecha_ono(false);
          flecha('creacion', false);
        }
        break;
  
      default:
        break;
    }
  }

  const [flecha_ord, setFlecha_ord] = useState('')

  function flecha(flecha:String, dir:Boolean)
  {
    if('titulo' == flecha){
        if(dir)
        {
          setFlecha_ord('titulo_desc')
        }else{
          setFlecha_ord('titulo_asc')
        }
    }

    if('fecha_cum' == flecha){
        if(dir)
        {
          setFlecha_ord('fechacum_desc')
        }else{
          setFlecha_ord('fechacum_asc')
        }
    }
    if('fecha_ona' == flecha){
        if(dir)
        {
          setFlecha_ord('fechaona_desc')
        }else{
          setFlecha_ord('fechaona_asc')
        }
    }
    if('fecha_pro' == flecha){
        if(dir)
        {
          setFlecha_ord('fechapro_desc')
        }else{
          setFlecha_ord('fechapro_asc')
        }
    }
    if('fecha_ord' == flecha){
        if(dir)
        {
          setFlecha_ord('fechaord_desc')
        }else{
          setFlecha_ord('fechaord_asc')
        }
    }
    if('fecha_def' == flecha){
        if(dir)
        {
          setFlecha_ord('fechadef_desc')
        }else{
          setFlecha_ord('fechadef_asc')
        }
    }

    if('creacion' == flecha){
        if(dir)
        {
          setFlecha_ord('creacion_desc')
        }else{
          setFlecha_ord('creacion_asc')
        }
    }
    
  }
  


  return (
    <div id='ProductList'>
      
      <div className='buttons_top'>
        <Button component={Link} to="add" variant="contained">
          Nuevo Aniversario
        </Button>

        <input
          type="text"
          style={{
            fontSize: '12px',
            textAlign: 'left',
            width: '200px',
          }}
          placeholder="Buscar"
          onChange={handleInputChange}
        />

      </div>
        <div id="task_list_container" className="flex_container">
          
            <Box id='user_list' title="Aniversarios" subtitle="Lista"  >
            {!loading && <div id='loader'><CircularProgress /></div>}
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => name_order()} 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} style={{ width: '22%' }}>Nombre
                  {flecha_ord === 'titulo_desc' && (
                        
                    <KeyboardArrowDownIcon  />
                  )}
                  {flecha_ord === 'titulo_asc' && (
                    <KeyboardArrowUpIcon  />
                  )}
                  </TableCell>
                  <TableCell onClick={() => fecha(1)} 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} style={{ width: '13%' }}>Cumpleaños
                  {flecha_ord === 'fechacum_desc' && (
                        
                    
                    <KeyboardArrowUpIcon  />
                  )}
                  {flecha_ord === 'fechacum_asc' && (
                    <KeyboardArrowDownIcon  />
                  )}
                  </TableCell>
                  <TableCell onClick={() => fecha(2)} 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} style={{ width: '12%' }}>Onomástica
                  {flecha_ord === 'fechaona_desc' && (
                        
                        <KeyboardArrowUpIcon  />
                  )}
                  {flecha_ord === 'fechaona_asc' && (
                    
                    <KeyboardArrowDownIcon  />
                  )}
                  </TableCell>
                  <TableCell onClick={() => fecha(3)} 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} style={{ width: '12%' }}>Profesión
                  {flecha_ord === 'fechapro_desc' && (
                      <KeyboardArrowUpIcon  />
                  )}
                  {flecha_ord === 'fechapro_asc' && (
                      <KeyboardArrowDownIcon  />
                  )}
                      </TableCell>
                  <TableCell onClick={() => fecha(4)} 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} style={{ width: '12%' }}>Ordenacion
                  {flecha_ord === 'fechaord_desc' && (
                    <KeyboardArrowUpIcon  />
                  )}
                  {flecha_ord === 'fechaord_asc' && (
                    <KeyboardArrowDownIcon  />
                  )}
                      </TableCell>
                  <TableCell onClick={() => fecha(5)} 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} style={{ width: '12%' }}>Defunción
                  {flecha_ord === 'fechadef_desc' && (
                    <KeyboardArrowUpIcon  />
                  )}
                  {flecha_ord === 'fechadef_asc' && (
                    <KeyboardArrowDownIcon  />
                  )}
                      </TableCell>
                  
                  <TableCell onClick={() => fecha(6)} 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} style={{ width: '12%' }}>Creacion
                  {flecha_ord === 'creacion_desc' && (
                        
                    <KeyboardArrowDownIcon  />
                  )}
                  {flecha_ord === 'creacion_asc' && (
                    <KeyboardArrowUpIcon  />
                  )}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {list.map((list_item,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell onClick={()=> {window.location.href = `/admin/aniversarios/edit/${list_item.aniversario_id}`}} >    
                        <div className='flexWImage'>
                          <img className='ListImage' src={list_item.image} />
                          <div className="flexWImage_right">
                          {list_item.name}
                          </div>
                        </div>
                        
                      </TableCell>

                     
                      <TableCell>
                        {list_item.birth_date}
                      </TableCell>
                      <TableCell>
                        {list_item.fecha_santoral}
                      </TableCell>
                      <TableCell>
                        {list_item.profession_date}
                      </TableCell>
                      <TableCell>
                        {list_item.ordination_date}
                      </TableCell>
                      <TableCell>
                        {list_item.death_date}
                      </TableCell>
                      <TableCell>
                        {list_item.created_at}
                      </TableCell>

                      <TableCell> 
                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(list_item.aniversario_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            send={deleteItem}
        />
    </div>
  );
};



export default AniversariosList;