import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import AlertDialog from 'elements/AlertDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const AcuerdosCatetegoryList = () => {

  interface List {
    acuerdos_category_id: number;
    title: string;
    orden: number;
  }

  const [loading, setLoading] = useState(false); 
  const [list, setList] = useState<List []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);
  const [numItem, setNumItem] = useState(10);

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'acuerdos_category_list',
          page: page,
          numItem:numItem
        }
      })
        .then((response) => {
          console.log("ul ",response.data); 
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList, numItem])
  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (id:number) => {
    setActiveItem(id)
    setOpenAlert(true)
  }

  const deleteItem = () => {
    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_acuerdos_category',
          id: activeItem,
        }
      })
  
      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1)
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }
  /*=========================================================*/
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);

  
const ordenItem = (idSubiendo:number, nuevoOrdenSubiendo:number, idBajando:number, nuevoOrdenBajando:number) => {
  axios.post(ProjectConfig.api_url, null,
    {
      params: {
        request:            'orden_acuerdos_category',
        idSubiendo:         idSubiendo,
        nuevoOrdenSubiendo: nuevoOrdenSubiendo,
        idBajando:          idBajando,
        nuevoOrdenBajando:  nuevoOrdenBajando,
      }
    })

    .then((response) => {
      console.log(response.data);

    })
    .catch((err) => {
      console.log(err.data);
    })
    .then(() => {
      setOpenAlert(false)
    })
}
const handleMouseEnter = () => {
  // Cambiar el cursor cuando el mouse entra en el título
  document.body.style.cursor = "pointer";
};

const handleMouseLeave = () => {
  // Restaurar el cursor cuando el mouse sale del título
  document.body.style.cursor = "default";
};

const subir = (category_id: number, orden: number) => {
  const currentIndex = list.findIndex(item => item.acuerdos_category_id === category_id);

  if (currentIndex > 0) {
    const updatedList = [...list];
    updatedList[currentIndex].orden = list[currentIndex - 1].orden;
    updatedList[currentIndex - 1].orden = orden;

    // ID y nuevos órdenes
    const idSubiendo = updatedList[currentIndex].acuerdos_category_id;
    const nuevoOrdenSubiendo = updatedList[currentIndex].orden;
    const idBajando = updatedList[currentIndex - 1].acuerdos_category_id;
    const nuevoOrdenBajando = updatedList[currentIndex - 1].orden;

    // Actualiza el estado con la lista modificada
    setList(updatedList);
    updatedList.sort((a, b) => a.orden - b.orden);
    setList(updatedList);

    ordenItem(idSubiendo, nuevoOrdenSubiendo, idBajando, nuevoOrdenBajando);
  }
};

const bajar = (category_id: number, orden: number) => {
  const currentIndex = list.findIndex(item => item.acuerdos_category_id === category_id);

  if (currentIndex < list.length - 1) {
    const updatedList = [...list];
    updatedList[currentIndex].orden = list[currentIndex + 1].orden;
    updatedList[currentIndex + 1].orden = orden;

    // ID y nuevos órdenes
    const idBajando = updatedList[currentIndex].acuerdos_category_id;
    const nuevoOrdenBajando = updatedList[currentIndex].orden;
    const idSubiendo = updatedList[currentIndex + 1].acuerdos_category_id;
    const nuevoOrdenSubiendo = updatedList[currentIndex + 1].orden;

    // Actualiza el estado con la lista modificada
    setList(updatedList);

    // Ordena la lista en función del campo "orden"
    updatedList.sort((a, b) => a.orden - b.orden);
    setList(updatedList);
    ordenItem(idSubiendo, nuevoOrdenSubiendo, idBajando, nuevoOrdenBajando);
    // console.log('Subio: ' + idSubiendo  + ' Nuevo puesto: ' + nuevoOrdenSubiendo)
    // console.log('Bajo: '  + idBajando   + ' Nuevo puesto: ' + nuevoOrdenBajando)


  }

  // Devuelve un objeto vacío si no hay cambios
  return {};
};

  // Función para manejar el cambio en el select
  const handleCantidadChange = (event:any) => {
    const newValue = event.target.value; // Nuevo valor seleccionado
    setNumItem(newValue); 
    setPage(1);
  };


  
  return (
    <div id='ProductList'>
      
      <div className='buttons_top'>
        <Button component={Link} to="add" variant="contained">
          Nueva Categoria
        </Button>
        {/* <select value={numItem} onChange={handleCantidadChange}>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="1000">All</option>
       </select> */}

      </div>
        <div id="task_list_container" className="flex_container">
          
            <Box id='user_list' title="Acuerdos Capitulares " subtitle="Categorías"  >
            {!loading && <div id='loader'><CircularProgress /></div>}
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Categoría</TableCell>
                  <TableCell></TableCell>
                  {/* <TableCell>Orden</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                  {list.map((list_item,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell onClick={()=> {window.location.href = `/admin/acuerdos/categorias/edit/${list_item.acuerdos_category_id}`}} >    
                        {list_item.title}
                      </TableCell>

                      <TableCell> 
                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(list_item.acuerdos_category_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>

                        <Button component={Link} to={`/admin/acuerdos/${list_item.acuerdos_category_id}`} variant="contained">
                          Ver Acuerdos
                        </Button>
                        
                        


                    </TableCell>
                    {/* <TableCell>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        {
                          (list_item.orden != 1 && page == 1 ) && (
                            <KeyboardArrowUpIcon
                              onClick={() => subir(list_item.acuerdos_category_id, list_item.orden)}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            />
                          )
                        }
                        {
                          (list.length != (i + 1) && page == 1 ) && (
                            <KeyboardArrowDownIcon
                              onClick={() => bajar(list_item.acuerdos_category_id, list_item.orden)}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            />
                          )
                        }

                      </div>
                    </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            subtitle="¿Esta acción tambien borrará los elementos de esta categoría. ¿Desea continuar?"
            send={deleteItem}
        />
    </div>
  );
};



export default AcuerdosCatetegoryList;