import {Button, IconButton, TextareaAutosize, TextField} from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";





  
export default withSnackbar(
  function MaximasEditor(props : any) {

      /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let {maxima_id } = useParams();
  /*=======================================
  =======================================*/


  const navigate = useNavigate();
  const defaultValues: any = {
    maxima_id: maxima_id? maxima_id : "null",
    content: "",
  };
  
  const [loading, setLoading] = useState(false); 
  const [formValues, setFormValues] = useState(defaultValues)
  const [files, setFiles] = useState<any>([]);




  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };




  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (maxima_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "maximas_details",
            id: maxima_id,
          },
        })
        .then((response) => {
          console.log("formValues", response.data)
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/

  


  /*=======================================
    SUBMIT
  =======================================*/

  const handleSubmit = () => {
    console.log(formValues);

    //AXIOS
    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "add_edit_maximas",
          formValues: formValues,
        },
      })
      .then((response) => {
        console.log(response);
        if(response.data){
          navigate("/admin/maximas");
        }else{
          enqueueSnackbar("Error al guardar", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err.data);
      });


  }
  /*====================================
  =====================================*/







  return (
      <div id="MaximasForm">
        <div className="userForm_right">
          <Box id="maximas_editor" className="box_editor" title="Maximas" subtitle="Editar"  >
            <div className='input_group'>
                <div className='input_group'>
                    <div className='input_row'>

                    <TextField 
                        id="content"
                        name="content"
                        label="Contenido"
                        type="text"
                        required
                        fullWidth
                        multiline
                        value={formValues.content}
                        onChange={handleInputChange}
                        sx={{ width: '500px' }}
                      />

                  </div>


                </div>


              

            </div>
            <div className='buttons'>
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancelar</Button>
              <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
            </div>
          </Box>
        </div>

        
          


      </div>
    
  );
})