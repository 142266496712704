import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';

const AcuerdosList = () => {

  /*=======================================
    GET  ID FROM URL
  =======================================*/
  let {acuerdos_category_id} = useParams();
  /*=======================================
  =======================================*/

  const [loading, setLoading] = useState(false); 
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);

  const [categoryName, setCategoryName] = useState('');


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'acuerdos_list',
          acuerdos_category_id: acuerdos_category_id,
          page: page,
        }
      })
        .then((response) => {
          console.log("ul ",response.data); 
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setCategoryName(response.data.category_name)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (id:number) => {
    setActiveItem(id)
    setOpenAlert(true)
  }
  
  const deleteItem = () => {
    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_acuerdo',
          id: activeItem,
        }
      })
  
      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1)
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }
  /*=========================================================*/
    
  const [openAlert, setOpenAlert] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  

  
  return (
    <div id='AcuerdosList'>
      
      <div className='buttons_top'>

        <Button component={Link} to="/admin/acuerdos/categorias" variant="contained" color='secondary'>
          Volver a categorías
        </Button>

        <Button component={Link} to="add" variant="contained">
          Nuevo acuerdo
        </Button>

      </div>
        <div id="task_list_container" className="flex_container">
          
            <Box id='user_list' title={categoryName+" - Acuerdos Capitulares"} subtitle="Lista"  >
            {!loading && <div id='loader'><CircularProgress /></div>}
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {list.map((list_item,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell onClick={()=> {window.location.href = `/admin/acuerdos/${acuerdos_category_id}/edit/${list_item.acuerdo_id}`}} >    
                        {list_item.title}
                      </TableCell>

                     
                     
                      <TableCell> 
                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(list_item.acuerdo_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            send={deleteItem}
        />
    </div>
  );
};



export default AcuerdosList;