import react, { useEffect, useState } from 'react';
import {
    Button,
    Chip,
    CircularProgress,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import axios from 'axios';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import AlertDialog from 'elements/AlertDialog';
import Box from 'elements/Box';
import { ProjectConfig } from 'Global';

import 'assets/css/main.css';
import Filters from 'elements/Filters/Filters';
import FiltersApplied from 'elements/Filters/FiltersApplied';

export interface TextosLiturgicos {
    texto_liturgico_id: number;
    name: string;
    color: string;
    color_hex: string;
    date?: string;
    time?: string;
    day?: string;
    week?: string;
    colourName?: string; 
}

export const fakeAPIEditor: {[id: number | string]: TextosLiturgicos} = {
    "1": {
        texto_liturgico_id: 1,
        name: 'Bautismo del Señor',
        color: 'Rojo',
        color_hex: '#d22b2b',
    },
    "2": {
        texto_liturgico_id: 2,
        name: 'Bautismo del Señor',
        color: 'Morado',
        color_hex: '#800080',
    },
    "3": {
        texto_liturgico_id: 3,
        name: 'Bautismo del Señor',
        color: 'Verde',
        color_hex: '#20763d',
    },
    "4": {
        texto_liturgico_id: 4,
        name: 'Bautismo del Señor',
        color: 'Rosado',
        color_hex: '#FFC0CB',
    },
} 

const fakeAPI: {total_pages: number, page: number, list: TextosLiturgicos[]} = {
    total_pages: 10,
    page: 1,
    list: [
        fakeAPIEditor["1"],
        fakeAPIEditor["2"],
        fakeAPIEditor["3"],
        fakeAPIEditor["4"],
    ],
}

export default function TextosLiturgicosList ( props: any ) {
    /*================================================
    FILTERS
    =================================================*/
    const filters = [
        {type: "select", label: "Tiempo", name: "tiempo", options: [{label: 'Tiempo ordinario', value: 'tiempo_ordinario'}, {label: 'Cuaresma', value: 'cuaresma'}, {label: 'Semana Santa', value: 'semana_santa'}, {label: 'Pascua', value: 'pascua'}, {label: 'Adviento', value: 'adviento'}, {label: 'Navidad', value: 'navidad'},]},
        {type: "select", label: "Día", name: "dia", options: [{label: 'Domingo', value: '0'}, {label: 'Lunes', value: '1'}, {label: 'Martes', value: '2'}, {label: 'Miércoles', value: '3'}, {label: 'Jueves', value: '4'}, {label: 'Viernes', value: '5'}, {label: 'Sábado', value: '6'},]},
        {type: "select", label: "Semana", name: "semana", options: Array.from({length: 35}, (e, i) => Object({label: `Semana ${i+1}`, value: (i+1).toString()}))}
    ]
    const [applyFilters, setApplyFilters] = useState({});
    const [filtersValues, setFiltersValues] = useState<any>({});
    const [openFilters, setOpenFilters] = useState(false);

    const setFilters = (tempFilters: any) => {
        setFiltersValues(tempFilters);
    }
    /*================================================= 
    ==================================================*/

    // HOOKS
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') || 1;

    const navigate = useNavigate();

    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<TextosLiturgicos[]>([]);
    const [total_pages, setTotalPages] = useState(0);
    const [activeItem, setActiveItem] = useState<TextosLiturgicos | any>(null);
    

    useEffect (() => {
        setLoading(false);
        loadData();
    }, []);

    useEffect (() => {
        loadData();
    }, [filtersValues, page]);

    // FUNCTIONS
    const loadData = () => {
        setLoading(true);
        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'textos_liturgicos_list',
                params: {
                    page: page,
                    filters: filtersValues,
                },
            }
        })
        .then((response) => {
            console.log(response.data);
            setList(response.data.list);
            setTotalPages(response.data.total_pages);
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    // HANDLERS

    function handleDelete(): void {
        if (!activeItem) return;
        setLoading(true);
        axios.post(ProjectConfig.api_url, null, {
            params: {
                request: 'delete_textos_liturgicos',
                texto_liturgico_id: activeItem.texto_liturgico_id,
            }
        })
        .then((response) => {
            console.log(response.data);
            loadData();
        })
        .catch((err) => {
            console.log(err.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    // RENDER
    return (
        <>
            <div id='textosLiturgicosList'>
                <div className='buttons_top'>
                    <Filters
                        open={openFilters}
                        setOpen={setOpenFilters}
                        filters={filters}
                        setFilters={setFilters}
                        setApplyFilters={setApplyFilters}
                    />

                    <Button component={Link} to="add" variant="contained">
                        Nuevo Texto Litúrgico
                    </Button>
                </div>
                
                <div id="textos_liturgicos_list_container" className="flex_container">
                    <Box id='textos_liturgicos_list' title="Textos Litúrgicos" subtitle="Lista"  >
                        <FiltersApplied
                            applyFilters={applyFilters}
                            setApplyFilters={setApplyFilters}
                            filtersValues={filtersValues}
                            setFiltersValues={setFiltersValues}
                        />
                        
                        {loading && <div id='loader'><CircularProgress /></div>}
                        <Table stickyHeader aria-label="sticky table"> 
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Color</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {list.map((list_item,i) => (
                                <TableRow 
                                    key={i}
                                    hover
                                    role="checkbox"
                                >
                                    <TableCell onClick={() => {navigate(`edit/${list_item.texto_liturgico_id}`)}} >    
                                        {list_item.texto_liturgico_id}
                                    </TableCell>

                                    <TableCell onClick={() => {navigate(`edit/${list_item.texto_liturgico_id}`)}} >    
                                        {list_item.name}
                                    </TableCell>
                                    
                                    <TableCell onClick={() => {navigate(`edit/${list_item.texto_liturgico_id}`)}}>
                                        <Chip
                                            id='textos-liturgicos-chip'
                                            label={list_item.color} 
                                            variant="outlined"
                                            sx={{borderColor: list_item.color_hex, color: list_item.color_hex,}}
                                        />
                                    </TableCell>

                                    <TableCell> 
                                        <IconButton
                                            aria-label="delete"
                                            color="primary"
                                            onClick={() => {
                                                setActiveItem(list_item);
                                                setOpenAlert(true);
                                            }}
                                        >
                                            <DeleteOutlineOutlined />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        
                        {total_pages > 1 &&
                        <Pagination
                            count={total_pages}
                            page={Number(page)}
                            onChange={(e, value) => {
                                navigate(`?page=${value}`)
                            }}
                            color="primary"
                            sx={{mt: "20px", alignSelf: "center"}}
                        />
                        }
                    </Box>
                </div>
            </div>
            
            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                send={handleDelete}
            />
        </>
    );
}