import { Button, IconButton, TextareaAutosize, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from "Global";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import EventIcon from "@mui/icons-material/Event";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import App from "elements/HtmlEditor/App";
import AutocompleteSantoral from "./utils/AutocompleteSantoral";
import { useSnackbar, withSnackbar } from "notistack";
import moment from "moment";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const MIN_DATE = moment().subtract(350, "years");

export default withSnackbar(function AniversariosEditor(props: any) {
  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { aniversario_id } = useParams();
  /*=======================================
  =======================================*/

  const navigate = useNavigate();
  const defaultValues: any = {
    aniversario_id: aniversario_id ? aniversario_id : "null",
    image: "",
    name: "",
    birth_date: null,
    death_date: null,
    ordination_date: null,
    profession_date: null,
    santoral_name: "",
    santoral_id: null,
    bioHTML: "",
    bioJSON: null,
  };

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const [files, setFiles] = useState<any>([]);

  interface pdfData {
    file_id: any;
    file: any;
    name: string;
    estado: string;
  }

  const [filesPdf, setFilesPdf] = useState<pdfData[]>([]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
    TEXT EDITOR
  =======================================*/
  const [initialContent, setInitialContent] = useState<any>(null);

  const handleEditorChange = (htmlContent: any, jsonContent: any) => {
    setFormValues({
      ...formValues,
      bioHTML: htmlContent,
      bioJSON: jsonContent,
    });
  };
  /*=======================================
  =======================================*/

  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (aniversario_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "aniversarios_details",
            id: aniversario_id,
          },
        })
        .then((response) => {
          console.log("formValues", response.data);
          setFormValues(response.data);
          setInitialContent(response.data.bioJSON);
          setFilesPdf(response.data.files);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/

  /*=======================================
    SUBMIT
  =======================================*/
  const [progress, setProgress] = useState(0);

  const handleSubmit = () => {
    console.log(formValues);

    //FILES NEEDS TO BE UPLOADED USING FORMDATA, APART FROM THE OTHER FIELDS
    const formData = new FormData();
    formData.append("files", files);
    formData.append("formValues", JSON.stringify(formValues));
    // Agregar archivos al FormData
    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i]);
    }

    // Agregar archivos PDF al FormData
    for (let i = 0; i < filesPdf.length; i++) {
      formData.append(`filesPdf[${i}][file_id]`, filesPdf[i].file_id);
      formData.append(`filesPdf[${i}][file]`, filesPdf[i].file);
      formData.append(`filesPdf[${i}][name]`, filesPdf[i].name);
      formData.append(`filesPdf[${i}][estado]`, filesPdf[i].estado);
    }

    //AXIOS
    axios
      .post(ProjectConfig.api_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
        params: {
          request: "add_edit_aniversarios",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data) {
          navigate("/admin/aniversarios");
        } else {
          enqueueSnackbar("Error al guardar", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*====================================
  =====================================*/

  const handleAutocompleteChange = (santoral_name: any, santoral_id: any) => {
    console.log(santoral_name, santoral_id);
    setFormValues({
      ...formValues,
      santoral_name: santoral_name,
      santoral_id: santoral_id,
    });
  };

  const borrarPorIdPdf = (file_id: number) => {
    borrar_file(file_id);
    setFilesPdf((prevPdf) =>
      prevPdf.filter((file) => file.file_id !== file_id)
    );
  };

  const borrar_file = (directorio_id: Number) => {
    const formData = new FormData();
    formData.append("archivo_id", JSON.stringify(directorio_id));
    //AXIOS
    axios
      .post(ProjectConfig.api_url, formData, {
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
        params: {
          request: "delete_archivo",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data) {
          setFormValues({
            ...formValues,
            filePdf: "",
            file_id: 0,
          });
        } else {
          enqueueSnackbar("Error al borrar", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const [ident, setident] = useState(1000000001);
  const add_file = () => {
    setFilesPdf((current) => [
      ...current,
      {
        file_id: ident,
        file: "",
        name: "",
        estado: "nuevo",
      },
    ]);
    setident(ident + 1);
  };

  const handleFileInputChange = (
    file_id: number,
    file: any,
    name: string,
    estado: string
  ) => {
    actualizarFile(file_id, file, name, estado);

    //
  };

  const actualizarFile = (
    file_id: number,
    file: any,
    name: string,
    estado: string
  ) => {
    if (estado == "nuevo") {
      setFilesPdf((prevFile) =>
        prevFile.map((pdfRow) =>
          pdfRow.file_id === file_id
            ? {
                ...pdfRow,
                file: file,
                name: name,
              }
            : pdfRow
        )
      );
    } else {
      setFilesPdf((prevFile) =>
        prevFile.map((pdfRow) =>
          pdfRow.file_id === file_id
            ? {
                ...pdfRow,
                file: file,
                name: name,
                estado: "editable",
              }
            : pdfRow
        )
      );
    }

    
  };

  const handleTituloInputChange = (id: any, value: any) => {
    actualizarTituloPorIdPdf(id, value);

    //
  };

  const actualizarTituloPorIdPdf = (file_id: any, name: any) => {
    setFilesPdf((prevFile) =>
      prevFile.map((pdfRow) =>
        pdfRow.file_id === file_id
          ? {
              ...pdfRow,
              name: name,
            }
          : pdfRow
      )
    );
  };

  return (
    <div id="userForm">
      <div className="userForm_right">
        <Box
          id="aniversarios_editor"
          className="box_editor"
          title="Aniversario"
          subtitle="Editar"
        >
          <div className="input_group">
            <div className="input_row">
              {/* UPLOAD button with preview */}
              <div className="drawerImagesContainer">
                <div
                  className="drawerImagesContainerDiv"
                  onDrop={(e) => {
                    e.preventDefault();
                    // UPDATE FILE
                    const files = e.dataTransfer.files;
                    setFiles(files[0]);
                    const fileArray = Array.from(files).map((file: any) =>
                      URL.createObjectURL(file)
                    );
                    setFormValues({
                      ...formValues,
                      image: fileArray[0],
                    });
                    e.currentTarget.classList.remove("dropzone_active");
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.add("dropzone_active");
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.remove("dropzone_active");
                  }}
                >
                  {formValues.image && <img src={formValues.image} />}

                  <label
                    id="dropFileDiv"
                    className={formValues.image && "showOnlyOnHover"}
                  >
                    <IconButton
                      aria-label="delete"
                      size="small"
                      className="addFile_button"
                      component="span"
                    >
                      <CameraAltIcon />
                      <input
                        type="file"
                        multiple
                        hidden
                        onChange={(e) => {
                          const files = e.target.files;
                          if (files) {
                            // UPDATE FILE
                            setFiles(files[0]);
                            const fileArray = Array.from(files).map(
                              (file: any) => URL.createObjectURL(file)
                            );
                            setFormValues({
                              ...formValues,
                              image: fileArray[0],
                            });
                          }
                        }}
                      />
                    </IconButton>
                  </label>
                </div>
              </div>

              <div className="inputColumn">
                <div className="input_row">
                  <TextField
                    id="name"
                    name="name"
                    label="Nombres"
                    type="text"
                    required
                    fullWidth
                    value={formValues.name}
                    onChange={handleInputChange}
                  />

                  <AutocompleteSantoral
                    name={formValues.santoral_name}
                    onChange={handleAutocompleteChange}
                  />
                </div>

                <div className="input_row">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      label="Fecha de nacimiento"
                      inputFormat="DD/MM/YYYY"
                      minDate={MIN_DATE}
                      value={formValues.birth_date}
                      InputProps={{
                        endAdornment: <EventIcon />,
                      }}
                      showToolbar={false}
                      closeOnSelect
                      onChange={(newValue) => {
                        setFormValues({
                          ...formValues,
                          ["birth_date"]: moment(newValue).format("YYYY-MM-DD"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      label="Fecha de profesión religiosa"
                      inputFormat="DD/MM/YYYY"
                      minDate={MIN_DATE}
                      value={formValues.profession_date}
                      InputProps={{
                        endAdornment: <EventIcon />,
                      }}
                      showToolbar={false}
                      closeOnSelect
                      onChange={(newValue) => {
                        setFormValues({
                          ...formValues,
                          ["profession_date"]:
                            moment(newValue).format("YYYY-MM-DD"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      label="Fecha ordenación sacerdotal"
                      inputFormat="DD/MM/YYYY"
                      minDate={MIN_DATE}
                      value={formValues.ordination_date}
                      InputProps={{
                        endAdornment: <EventIcon />,
                      }}
                      showToolbar={false}
                      closeOnSelect
                      onChange={(newValue) => {
                        setFormValues({
                          ...formValues,
                          ["ordination_date"]:
                            moment(newValue).format("YYYY-MM-DD"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="input_row">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      label="Fecha de fallecimiento"
                      inputFormat="DD/MM/YYYY"
                      minDate={MIN_DATE}
                      value={formValues.death_date}
                      InputProps={{
                        endAdornment: <EventIcon />,
                      }}
                      showToolbar={false}
                      closeOnSelect
                      onChange={(newValue) => {
                        setFormValues({
                          ...formValues,
                          ["death_date"]: moment(newValue).format("YYYY-MM-DD"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>

          <App
            placeholder="Biografía"
            handleEditorChange={handleEditorChange}
            content={initialContent}
          />

          {filesPdf.length > 0 &&
            filesPdf.map((fileRow, index) => (
              <div key={index}>
                {/* <p>  Hola </p> */}
                {/* <p>  {fileRow.name} </p> */}
                <label>Titulo</label>
                <div className="inputColumn">
                  <input
                    type="text"
                    name={"titulo_" + index}
                    id={"titulo_" + index}
                    onChange={(e) =>
                      actualizarTituloPorIdPdf(fileRow.file_id, e.target.value)
                    }
                    value={fileRow.name}
                  />
                </div>
                {/* <br/> */}
                <br />
                <div className="drawerImagesContainer">
                  <div
                    id="multimediaFileDropBox"
                    className={
                      "drawerImagesContainerDiv " +
                      (fileRow.file != "" ? "hasFile" : "")
                    }
                    onDrop={(e) => {
                      e.preventDefault();
                      // UPDATE FILE
                      const files = e.dataTransfer.files;
                      const fileName = files[0].name;
                      console.log("fileName 1");
                      console.log(fileName);
                      handleFileInputChange(
                        fileRow.file_id,
                        files[0],
                        fileName,
                        fileRow.estado
                      );

                      const fileArray = Array.from(files).map((file: any) =>
                        URL.createObjectURL(file)
                      );

                      e.currentTarget.classList.remove("dropzone_active");
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.currentTarget.classList.add("dropzone_active");
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      e.currentTarget.classList.remove("dropzone_active");
                    }}
                  >
                    {fileRow.file && (
                      <span className="fileUploaded">
                        <AttachFileIcon />
                        {fileRow.name}
                      </span>
                    )}

                    <label
                      id="dropFileDiv"
                      className={fileRow.file && "showOnlyOnHover"}
                    >
                      <IconButton
                        aria-label="delete"
                        size="small"
                        className="addFile_button"
                        component="span"
                      >
                        <FileUploadIcon />

                        <input
                          type="file"
                          multiple
                          hidden
                          accept=".pdf"
                          onChange={(e) => {
                            const files = e.target.files;
                            if (files) {
                              //get file name
                              const fileName = files[0].name;
                              // console.log("fileName 2")
                              // console.log(fileName)
                              // UPDATE FILE
                              handleFileInputChange(
                                fileRow.file_id,
                                files[0],
                                fileName,
                                fileRow.estado
                              );
                              const fileArray = Array.from(files).map(
                                (file: any) => URL.createObjectURL(file)
                              );
                            }
                          }}
                        />
                      </IconButton>
                      Arrastra aquí un archivo o da click para subirlo.
                    </label>
                  </div>
                  {fileRow.file != "" && (
                    <Button
                      onClick={() => borrarPorIdPdf(fileRow.file_id)}
                      variant="contained"
                      className="button_1"
                    >
                      Borrar
                    </Button>
                  )}
                </div>
              </div>
            ))}

          <>
            <br></br>
            <br></br>
            {/* <br></br> */}
            <Button onClick={add_file} variant="contained" className="button_1">
              Agregar PDF
            </Button>
          </>

          <div className="buttons">
            <Button
              onClick={() => navigate(-1)}
              variant="outlined"
              className="button_2"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className="button_1"
            >
              Guardar
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
});
