import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import FiltersApplied from 'elements/Filters/FiltersApplied';

const FiestasList = () => {

  const [loading, setLoading] = useState(false); 
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);



  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'fiestas_list',
          filters: filtersValues,
          page: page,
        }
      })
        .then((response) => {
          console.log("ul ",response.data); 
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (id:number) => {
    setActiveItem(id)
    setOpenAlert(true)
  }

  const deleteItem = () => {
    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_fiesta',
          id: activeItem,
        }
      })
  
      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1)
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }
  /*=========================================================*/

// call global snackbar
  
  const [openAlert, setOpenAlert] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [textoEscrito, setTextoEscrito] = useState('');

  const [filtersValues, setFiltersValues] = useState<any>({});

  function handleInputChange(event:any) {
    const nuevoTexto = event.target.value;
    setTextoEscrito(nuevoTexto);


    filtersValues.texto = event.target.value;

    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'fiestas_list',
          filters: filtersValues,
          page: page,

        }
      })
      .then((response) => {
        setList(response.data.list)
        setTotalPages(response.data.total_pages)




      })
      .catch((err) => {
        console.log(err.data);
      })
    localStorage.setItem('page', page.toString())
  }

  

  
  
  return (
    <div id='ProductList'>
      
      <div className='buttons_top'>
        <Button component={Link} to="add" variant="contained">
          Nueva Propio
        </Button>

          {/* <input
          type="text"
          style={{
            fontSize: '12px',
            textAlign: 'left',
            width: '200px',
          }}
          placeholder="Buscar"
          onChange={handleInputChange}
        /> */}

      </div>
        <div id="task_list_container" className="flex_container">
          
            <Box id='user_list' title="FIESTAS MOVIBLES Y PROPIAS" subtitle="Lista"  >
            {!loading && <div id='loader'><CircularProgress /></div>}

            

            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Titulo</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {list.map((list_item,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell onClick={()=> {window.location.href = `/admin/fiestas/edit/${list_item.fiesta_id}`}} >    
                        <div className='flexWImage'>
                          {/* <img className='ListImage' src={list_item.image} /> */}
                          <div className="flexWImage_right">
                          {list_item.titulo}
                          </div>
                        </div>
                        
                      </TableCell>

                     
                      <TableCell>
                        {list_item.date_data}
                      </TableCell>

                      <TableCell> 
                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(list_item.fiesta_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            send={deleteItem}
        />
    </div>
  );
};



export default FiestasList;