import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CelebracionesList = () => {

  const [loading, setLoading] = useState(false); 
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);
  const [order, setOrder] = useState(['title', 'asc'])


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'celebraciones_list',
          page: page,
          filters: filtersValues,
          order:order,
        }
      })
        .then((response) => {
          console.log("ul ",response.data); 
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList,order])
  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (id:number) => {
    setActiveItem(id)
    setOpenAlert(true)
  }

  const deleteItem = () => {
    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_celebracion',
          id: activeItem,
        }
      })
  
      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1)
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }
  /*=========================================================*/
  
  const [openAlert, setOpenAlert] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  
  const [filtersValues, setFiltersValues] = useState<any>({});

  function handleInputChange(event:any) {
    const nuevoTexto = event.target.value;
    // setTextoEscrito(nuevoTexto);


    filtersValues.texto = event.target.value;

    setPage(1);
    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'celebraciones_list',
          filters: filtersValues,
          page: page,
          order:order,

        }
      })
      .then((response) => {
        setList(response.data.list)
        setTotalPages(response.data.total_pages)




      })
      .catch((err) => {
        console.log(err.data);
      })
    localStorage.setItem('page', page.toString())
  }

  const handleMouseEnter = () => {
    // Cambiar el cursor cuando el mouse entra en el título
    document.body.style.cursor = "pointer";
  };

  const handleMouseLeave = () => {
    // Restaurar el cursor cuando el mouse sale del título
    document.body.style.cursor = "default";
  };

  const [order_name, setOrder_name] = useState(true)
  const [order_fecha, setOrder_fecha] = useState(false)
  const [order_cre, setOrder_cre] = useState(false)
  
  function name_order()
  {
    if(order[0] == 'title')
    {
      if(order[1] == 'asc'){
        setOrder(['title', 'desc']);
        flecha('titulo', true);
      }else if(order[1] == 'desc'){
        setOrder(['title', 'asc']);
        flecha('titulo', false);
      }
    }else{
      setOrder(['title', 'asc']);
      flecha('titulo', false);
    }
  }

  function fecha_order()
  {
    if(!order_fecha)
    {
      setOrder(['fecha', 'ASC']);
      setOrder_fecha(true);
      flecha('fecha', true);
    }if(order_fecha)
    {
      setOrder(['fecha', 'DESC']);
      setOrder_fecha(false);
      flecha('fecha', false);
    }

  }
  function fecha_cre()
  {
    if(!order_cre)
    {
      setOrder(['fecha_cre', 'ASC']);
      setOrder_cre(true);
      flecha('creacion', true);
    }if(order_cre)
    {
      setOrder(['fecha_cre', 'DESC']);
      setOrder_cre(false);
      flecha('creacion', false);
    }

  }

  
  const [flecha_ord, setFlecha_ord] = useState('')

  function flecha(flecha:String, dir:Boolean)
  {
    if('titulo' == flecha){
        if(dir)
        {
          setFlecha_ord('titulo_desc')
        }else{
          setFlecha_ord('titulo_asc')
        }
    }

    if('fecha' == flecha){
        if(dir)
        {
          setFlecha_ord('fecha_desc')
        }else{
          setFlecha_ord('fecha_asc')
        }
    }

    if('creacion' == flecha){
        if(dir)
        {
          setFlecha_ord('creacion_desc')
        }else{
          setFlecha_ord('creacion_asc')
        }
    }
    
  }
  
  return (
    <div id='CelebracionesList'>
      
      <div className='buttons_top'>
        <Button component={Link} to="add" variant="contained">
          Nueva oración
        </Button>

        <input
          type="text"
          style={{
            fontSize: '12px',
            textAlign: 'left',
            width: '200px',
          }}
          placeholder="Buscar"
          onChange={handleInputChange}
        />
      </div>
        <div id="task_list_container" className="flex_container">
          
            <Box id='user_list' title="Celebraciones" subtitle="Lista"  >
            {!loading && <div id='loader'><CircularProgress /></div>}
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell 
                      onClick={() => name_order()} 
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{ width: '50%' }}
                    >
                      Título
                      {flecha_ord === 'titulo_desc' && (
                        
                        <KeyboardArrowDownIcon  />
                      )}
                      {flecha_ord === 'titulo_asc' && (
                        <KeyboardArrowUpIcon  />
                      )}
                  </TableCell>

                  <TableCell onClick={() => fecha_order()}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave} style={{ width: '20%' }}>
                      Fecha Celebración
                    {flecha_ord === 'fecha_desc' && (
                      <KeyboardArrowUpIcon  />
                    )}
                    {flecha_ord === 'fecha_asc' && (
                      <KeyboardArrowDownIcon  />
                    )}
                  </TableCell>
                  <TableCell onClick={() => fecha_cre()}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} style={{ width: '20%' }}>
                    Creacion {flecha_ord === 'creacion_desc' && (
                    <KeyboardArrowDownIcon  />
                  )}
                  {flecha_ord === 'creacion_asc' && (
                    <KeyboardArrowUpIcon  />
                  )}
                  </TableCell>
                  <TableCell  style={{ width: '10%' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {list.map((list_item,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell onClick={()=> {window.location.href = `/admin/celebraciones/edit/${list_item.celebracion_id}`}} >    
                        
                      {list_item.title}
                        
                      </TableCell>

                      <TableCell>
                        {list_item.date}
                      </TableCell>
                      <TableCell>
                        {list_item.created_at}
                      </TableCell>


                     
                     
                      <TableCell> 
                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(list_item.celebracion_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>
        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            send={deleteItem}
        />
    </div>
  );
};



export default CelebracionesList;