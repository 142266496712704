import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Link from "@mui/material/Link";

import ic_menu from 'icons/ic_menu.svg';





import ic_usuarios from 'icons/ic_usuarios.svg';
import ic_santoral from 'icons/ic_santoral.svg';
import ic_fiestas from 'icons/ic_santoral.svg';
import ic_aniversarios from 'icons/ic_aniversarios.svg';
import ic_ano_liturgico from 'icons/ic_ano_liturgico.svg';
import ic_celebraciones from 'icons/ic_celebraciones.svg';
import ic_constituciones from 'icons/ic_constituciones.svg';
import ic_directorio from 'icons/ic_directorio.svg';
import ic_liturgia_horas from 'icons/ic_liturgia_horas.svg';
import ic_maximas from 'icons/ic_maximas.svg';
import ic_misas from 'icons/ic_misas.svg';
import ic_multimedia from 'icons/ic_multimedia.svg';
import ic_notificaciones from 'icons/ic_notificaciones.svg';
import ic_oraciones from 'icons/ic_oraciones.svg';
import ic_acuerdos_capitulares from 'icons/ic_acuerdos_capitulares.svg';



export default function MainMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='menu_button_container'>
      

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/usuarios">
        <img src={ic_usuarios} />
        <span>Usuarios</span>
      </Button>

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/administradores">
        <img src={ic_usuarios} />
        <span>Administradores</span>
      </Button>

      {/* <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/menu">
        <img src={ic_directorio} />
        <span>Menu</span>
      </Button> */}

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/santoral">
        <img src={ic_santoral} />
        <span>Santoral</span>
      </Button>
      
      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/fiestas">
        <img src={ic_fiestas} />
        <span>Propios</span>
      </Button>

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/aniversarios">
        <img src={ic_aniversarios} />
        <span>Aniversarios</span>
      </Button>

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/oraciones">
        <img src={ic_oraciones} />
        <span>Oraciones</span>
      </Button>

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/maximas">
        <img src={ic_maximas} />
        <span>Máximas</span>
      </Button>

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/textos_liturgicos">
        <img src={ic_liturgia_horas} /> {/*CHANGE IMG */}
        <span>Textos Litúrgicos</span>
      </Button>

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/configurar_ano_liturgico">
        <img src={ic_ano_liturgico} /> {/*CHANGE IMG */}
        <span>Configurar Año Litúrgico</span>
      </Button>



      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/multimedia">
        <img src={ic_multimedia} />
        <span>Multimedia</span>
      </Button>

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/celebraciones">
        <img src={ic_celebraciones} />
        <span>Celebraciones</span>
      </Button>


      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/acuerdos/categorias">
        <img src={ic_acuerdos_capitulares} />
        <span>Acuerdos capitulares</span>
      </Button>

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/directorio">
        <img src={ic_directorio} />
        <span>Directorio</span>
      </Button>

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/constituciones/categorias">
        <img src={ic_constituciones} />
        <span>Constituciones</span>
      </Button>
      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/ingresos">
        <img src={ic_maximas} />
        <span>Ingresos</span>
      </Button>

      <Button id="menu_button" fullWidth LinkComponent={Link} href="/admin/notificaciones">
        <img src={ic_notificaciones} />
        <span>Notificaciones</span>
      </Button>


    </div>
  );
}
