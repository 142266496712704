import {Button, FormControlLabel, IconButton, styled, Switch, SwitchProps, TextareaAutosize, TextField} from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useSnackbar, withSnackbar } from "notistack";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


export default withSnackbar(
  function MultimediaEditor(props : any) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  /*=======================================
    GET SANTORAL ID FROM URL
  =======================================*/
  let {multimedia_id } = useParams();
  /*=======================================
  =======================================*/


  const navigate = useNavigate();
  const defaultValues: any = {
    multimedia_id: multimedia_id? multimedia_id : "null",
    title: "",
    description: "",
    file: "",
    isVideo: false,
  };
  
  const [loading, setLoading] = useState(false); 
  const [formValues, setFormValues] = useState(defaultValues)
  const [files, setFiles] = useState<any>([]);




  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (multimedia_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "multimedia_details",
            id: multimedia_id,
          },
        })
        .then((response) => {
          console.log("formValues", response.data)
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/

  


  /*=======================================
    SUBMIT
  =======================================*/
  const [progress, setProgress] = useState(0);



  const handleSubmit = () => {
    console.log(formValues);
    
    //FILES NEEDS TO BE UPLOADED USING FORMDATA, APART FROM THE OTHER FIELDS
    const formData = new FormData();
    formData.append("files", files);

    //AXIOS
    axios
      .post(ProjectConfig.api_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );

        },
        params: {
          request: "add_edit_multimedia",
          formValues: formValues,
        },
      })
      .then((response) => {
        console.log(response);
        if(response.data){
          navigate("/admin/multimedia");
        }else {
          enqueueSnackbar("Error al guardar", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err.data);
      });


  }
  /*====================================
  =====================================*/







  return (
      <div id="MultimediaForm">
        <div className="userForm_right">
          <Box id="multimedia_editor" className="box_editor" title="Multimedia" subtitle="Editar"  >
            <div className='input_group'>
                <TextField 
                  id="title"
                  name="title"
                  label="Título"
                  type="text"
                  required
                  fullWidth
                  value={formValues.title}
                  onChange={handleInputChange}
                />

                <TextField 
                  id="description"
                  name="description"
                  label="Descripción"
                  type="text"
                  required
                  fullWidth
                  multiline
                  value={formValues.description}
                  onChange={handleInputChange}
                  sx={{ width: '500px' }}
                />

                <FormControlLabel
                  checked={formValues.isVideo}
                  
                  control={
                    <IOSSwitch 
                      sx={{ m: 1 }}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          ["isVideo"]: e.target.checked,
                          ["file"]: ""
                        });
                        setFiles([]);
                      }}
                    />
                  }
                  label="Video de Youtube"
                />

                {formValues.isVideo && (
                  <TextField
                    id="file"
                    name="file"
                    label="URL"
                    type="text"
                    required
                    fullWidth
                    value={formValues.file}
                    onChange={handleInputChange}
                  />
                )}





                {!formValues.isVideo && (
                  <div className="drawerImagesContainer">
                    <div id='multimediaFileDropBox' className={"drawerImagesContainerDiv " + (formValues.file !="" ? "hasFile" : "")}
                      onDrop={(e) => {
                          e.preventDefault();
                          // UPDATE FILE
                          const files = e.dataTransfer.files;
                          setFiles(files[0]);
                          const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                          setFormValues({
                            ...formValues,
                            image: fileArray[0],
                          });
                          e.currentTarget.classList.remove("dropzone_active");
                        }
                        } 
                        onDragOver={
                          (e) => {
                            e.preventDefault();
                            e.currentTarget.classList.add("dropzone_active");
                          }
                          
                        }
                        onDragLeave={
                          (e) => {
                            e.preventDefault();
                            e.currentTarget.classList.remove("dropzone_active");
                          }
                        }
                    
                      >
                      {formValues.file && (
                        <span className='fileUploaded'>
                          <AttachFileIcon />
                          {formValues.file}
                        </span>
                      )}
                      
                      <label id='dropFileDiv' className={formValues.file && ('showOnlyOnHover')}>
                        <IconButton 
                          aria-label="delete"
                          size="small"
                          className="addFile_button"
                          component="span"
                          
                        >
                          <FileUploadIcon/>
                         
                          <input 
                            type="file"
                            multiple 
                            hidden 
                            accept="audio/*,video/*,image/*,.pdf"
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files) {
                                  //get file name
                                  const fileName = files[0].name;
                                  // UPDATE FILE
                                  setFiles(files[0]);
                                  const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                                  
                                  setFormValues({
                                    ...formValues,
                                    file: fileName,
                                  });

                                }

                            }}
                          />
                          
                        </IconButton>
                        Arrastra aquí un archivo o da click para subirlo.
                      </label>
                    </div>
                  </div>
                )}
              

                    
                 


                
              

            </div>
            <div className='buttons'>
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancelar</Button>
              <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
            </div>
          </Box>
        </div>

        
          


      </div>
    
  );
})