import {Button, IconButton, TextareaAutosize, TextField} from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import App from "elements/HtmlEditor/App";
  import { useSnackbar, withSnackbar } from "notistack";



  export default withSnackbar(
    function AcuerdosEditor(props : any) {
  
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */
  
  /*=======================================
    GET  ID FROM URL
  =======================================*/
  let {acuerdos_category_id, acuerdo_id } = useParams();
  /*=======================================
  =======================================*/


  const navigate = useNavigate();
  const defaultValues: any = {
    acuerdo_id: acuerdo_id? acuerdo_id : "null",
    acuerdos_category_id: acuerdos_category_id,
    title: "",
    contentHTML: "",
    contentJSON: null,
  };
  
  const [loading, setLoading] = useState(false); 
  const [formValues, setFormValues] = useState(defaultValues)
  const [files, setFiles] = useState<any>([]);




  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };




  /*=======================================
    TEXT EDITOR
  =======================================*/
  const [initialContent, setInitialContent] = useState<any>(null);

  const handleEditorChange = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        contentHTML: htmlContent,
        contentJSON: jsonContent
      });
  }
  /*=======================================
  =======================================*/




  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (acuerdo_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "acuerdos_details",
            id: acuerdo_id,
          },
        })
        .then((response) => {
          console.log("formValues", response.data)
          setFormValues(response.data);
          setInitialContent(response.data.contentJSON);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/

  


  /*=======================================
    SUBMIT
  =======================================*/
  const [progress, setProgress] = useState(0);

  const handleSubmit = () => {
    //FILES NEEDS TO BE UPLOADED USING FORMDATA, APART FROM THE OTHER FIELDS
    const formData = new FormData();
    formData.append("formValues", JSON.stringify(formValues));

    //AXIOS
    axios
      .post(ProjectConfig.api_url, formData, {
        onUploadProgress: (progressEvent) => {
          setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );

        },
        params: {
          request: "add_edit_acuerdos",
        },
      })
      .then((response) => {
        console.log("res",response);
        if(response.data){
          navigate("/admin/acuerdos/"+acuerdos_category_id);
        }else{
          enqueueSnackbar("Error al guardar", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err.data);
      });


  }
  /*====================================
  =====================================*/







  return (
      <div id="userForm">
        <div className="userForm_right">
          <Box id="santoral_editor" className="box_editor" title="Acuerdo" subtitle="Editar"  >


                    
            <TextField 
              id="title"
              name="title"
              label="Título"
              type="text"
              required
              fullWidth
              value={formValues.title}
              onChange={handleInputChange}
            />

            <App 
              handleEditorChange={handleEditorChange} content={initialContent} 
            />

              

            <div className='buttons'>
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancelar</Button>
              <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
            </div>
          </Box>
        </div>

        
          


      </div>
    
  );
})