import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { IconButton } from '@mui/material';


export default function AutocompleteSantoral(props: any) {

  
  const [loading, setLoading] = useState(false); 

  /* =================================================
    PRODUCT AUTOCOMPLETE VARIABLES & FUNCTIONS
    =================================================*/
    interface IList {
      inputValue?: string,
      santoral_id?: number,
      name: string,
    };

    const filter = createFilterOptions<IList>();

    //SETTER
    const [list, setList] = useState<any[]>([]);
    
    useEffect(() => {
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'santoral_list',
          full_list: true,
        }
      })
        .then((response) => {
          setList(response.data.list);
          setLoading(true)
        })
        .catch((err) => {
          console.log(err.data);
        })
    }, [])
    /* ================================================= 
    ===================================================*/


  return (
    <Autocomplete
    
    value={props.name}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string') {
        props.onChange({
          name: newValue,
        });
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        props.onChange(
            newValue.inputValue,
            newValue.name,
            newValue.santoral_id
        );

      } else {

        props.onChange(
            newValue.name,
            newValue.santoral_id
        );
        
      }
      
    }}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);
      const { inputValue } = params;
      return filtered;
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    fullWidth
    id="autocomplete_santoral"
    className='autocomplete_santoral'
    options={list}
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.name;
    }}
    renderOption={
      (props, option) => 
      <li {...props} key={option.santoral_id}>
        <span className='autocomplete_name'>{option.name}</span>
      </li>
    }
    freeSolo
    renderInput={(params) => (
      <TextField 
      {...params} 
      label="Onomástica" 
      InputProps={{
        ...params.InputProps,
      }}/>
    )}
    
  />
  );
}
