import React, { Fragment } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { DeleteOutlineOutlined } from '@mui/icons-material';

import 'assets/css/main.css';

export default function FiltersApplied(props: any) {

   const {
    filtersValues,
    applyFilters,
    setApplyFilters,
    setFiltersValues,
  } = props;

  const getEllipsedString = (str: string, maxLength: number) => {
    return (str.length <= maxLength)? str : str.substring(0, maxLength) + "...";
  }

  return (
    <Fragment>
        {/* SHOWS ONLY IF PROPS.FILTERSVALUES EXISTS AND IS NOT EMPTY */}
        {Object.keys(props.applyFilters).length > 0 && (
        <div className="filtersApplied">
            {Object.keys(props.applyFilters).map((key, index) => {
            //if value is longer than 10 characters, cut it
            let value;
            let entireValue;

            if (typeof applyFilters[key] === 'string') {
                value = getEllipsedString(applyFilters[key], 10);
                entireValue = applyFilters[key];
            } else if (typeof applyFilters[key] === 'object') {
                if (applyFilters[key].value) {
                    if (typeof applyFilters[key].value === 'string') {
                        value = getEllipsedString(applyFilters[key].value, 20);
                        entireValue = applyFilters[key].value;
                    } else if (typeof applyFilters[key].value === 'object') {
                        value = applyFilters[key].value.map((item: any) => {
                            return getEllipsedString(item.name, 10);
                        }).join(", ");
                        entireValue = applyFilters[key].value.map((item: any) => {
                            return item.name;
                        }).join(", ");
                    }
                }
            }

            const handleKeyDelete = (key: any) => {
                if (typeof applyFilters[key] === 'string') {
                    delete filtersValues[key];
                    delete applyFilters[key];
                    setApplyFilters({...applyFilters});
                    setFiltersValues({...filtersValues});
                } else if (typeof applyFilters[key] === 'object') {
                    for (let name of applyFilters[key].names) {
                        delete filtersValues[name];
                    }
                    delete applyFilters[key];
                    setApplyFilters({...applyFilters});
                    setFiltersValues({...filtersValues});    
                }
            }

            return (
                <div className="filterItem" key={index}>
                    {/* cut key name and add dots  */}
                    <Tooltip title={entireValue}>
                        <span>{value}</span>
                    </Tooltip>

                    <IconButton 
                        size="small"
                        onClick={() => {
                            handleKeyDelete(key);
                        }
                    }>
                        <DeleteOutlineOutlined />
                    </IconButton>
                </div>
            )
            })}

        </div>
    )
    }
    </Fragment>
  );
}