import { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogContentText, IconButton, TextField } from '@mui/material';



export default function AlertDialog(props: any) {

    const {
        open,
        setOpen,
        send = () => {},
        subtitle
    } = props;
  
  return (
    <>
        {/* ALERT DIALOG */}
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <DialogContent  className="forced_box_editor">
                <div className="dialog_header">
                    <h2 className='dialog_h2'>Información</h2>
                    <DialogContentText>
                        {subtitle? subtitle : '¿Estás seguro de realizar esta acción?'}
                    </DialogContentText>
                </div>

                <div className="buttons">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setOpen(false);
                            send()
                        }}
                    >
                        Entendido
                    </Button>

                </div>

                </DialogContent>
            </Dialog>

    </>
  );
};

