import * as React from 'react';
import Romcal from 'romcal';


export default function Tests() {



const fecha = async () => {
  var calendar = await Romcal.calendarFor({ 
    year: 2022, 
    locale: 'es'
  });

// January 1 of 2019 is not part of the computed calendar for 2020
var day = calendar.find((day) => day.date === '2022-10-05T00:00:00.000Z');
console.log(day); // undefined


}

fecha();

// romcal.generateCalendar(2022).then((data1) => {
//   console.log("start",data1["2022-09-26"][0]["calendar"]); 
//   console.log("end",data1["2022-10-02"][0]["calendar"]); 
// });


  return (
    <div>tests</div>
  );
}


// 2022-09-26 / 2022-10-02 --- WEEK 26