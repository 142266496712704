import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';

const IngresosList = () => {


  const [loading, setLoading] = useState(false); 
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'ingresos_list',
          page: page,
        }
      })
        .then((response) => {
          console.log("ul ",response.data); 
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (id:number) => {
    setActiveItem(id)
    setOpenAlert(true)
  }


  /*=========================================================*/
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);
const [textoEscrito, setTextoEscrito] = useState('');
const [filtersValues, setFiltersValues] = useState<any>('');
  

  function handleInputChange(event:any) {
    var nuevoTexto = event.target.value;
    setTextoEscrito(nuevoTexto);
    setFiltersValues(event.target.value);



  axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'ingresos_list',
        filtroNew: filtersValues,
        envio: 1,
        page: page,

      }
    })
    .then((response) => {
      setList(response.data.list)
      setTotalPages(response.data.total_pages)




    })
    .catch((err) => {
      console.log(err.data);
    })
  localStorage.setItem('page', page.toString())
}
  

  
  return (
    <div id='IngresosList'>
      
      <div className='buttons_top'>


      <input
          type="text"
          style={{
            fontSize: '15px',
            textAlign: 'left',
            width: '200px',
          }}
          placeholder="Buscar"
          onChange={handleInputChange}
        />

      </div>
        <div id="task_list_container" className="flex_container">
          
            <Box id='user_list' title={"Ingresos"} subtitle="Lista"  >
            {!loading && <div id='loader'><CircularProgress /></div>}
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Modulo</TableCell>
                  <TableCell>Pais</TableCell>
                  <TableCell>Ciudad</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Hora</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {list.map((list_item,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell  >    
                        {list_item.usuario}
                      </TableCell>
                      <TableCell  >    
                        {list_item.modulo}
                      </TableCell>
                      <TableCell  >    
                        {list_item.pais}
                      </TableCell>
                      <TableCell  >    
                        {list_item.ciudad}
                      </TableCell>
                      <TableCell  >    
                        {list_item.fecha}
                      </TableCell>
                      <TableCell  >    
                        {list_item.hora}
                      </TableCell>

                     
                    
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>

        
    </div>
  );
};



export default IngresosList;