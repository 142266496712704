import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';

export default function AdminList(){

  const [loading, setLoading] = useState(false); 
  const [user_list, setUserList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'admin_list',
          page: page,
        }
      })
        .then((response) => {
          console.log("ul ",response.data); 
          setUserList(response.data.user_list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (user_id:number) => {
    setActiveItem(user_id)
    setOpenAlert(true)
  }


  const deleteItem = () => {
    axios.post(ProjectConfig.api_url, null, 
      {
        params: {
          request: 'delete_admin',
          user_id: activeItem,
        }
      })
  
      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1)
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }

  /*=========================================================*/

// call global snackbar
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);
  
  return (
    <div id='ProductList'>
      
      <div className='buttons_top'>
        <Button component={Link} to="add" variant="contained">
          Nuevo usuario
        </Button>

      </div>
        <div id="task_list_container" className="flex_container">
          
            <Box id='user_list' title="Administradores" subtitle="Lista"  >
            {!loading && <div id='loader'><CircularProgress /></div>}
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Nombre completo</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {user_list.map((user,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell onClick={()=> {window.location.href = `administradores/edit/${user.user_id}`}} >{user.name}</TableCell>

                     
                      <TableCell>
                        {user.email}
                      </TableCell>

                      <TableCell> 
                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(user.user_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
 
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            subtitle="¿Está seguro que desea eliminar este usuario?"
            send={deleteItem}
        />
    </div>
  );
};