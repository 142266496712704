import {BrowserRouter as Router , Route, Routes} from 'react-router-dom'
import Cookies from 'universal-cookie';

import UserList from 'components/admin/Users/UserList';
import UserEditor from 'components/admin/Users/UserEditor';
import AdminList from 'components/admin/Administrators/AdminList';
import AdminEditor from 'components/admin/Administrators/AdminEditor';

import MenuList from 'components/admin/Menu/MenuList';
import SantoralList from 'components/admin/Santoral/SantoralList';
import IngresosList from 'components/admin/Ingresos/ingresosList';
import SantoralEditor from 'components/admin/Santoral/SantoralEditor';
import FiestasList from 'components/admin/Fiestas/FiestasList';
import FiestasEditor from './components/admin/Fiestas/FiestasEditor';
import AniversariosList from 'components/admin/Aniversarios/AniversariosList';
import AniversariosEditor from 'components/admin/Aniversarios/AniversariosEditor';
import OracionesList from 'components/admin/Oraciones/OracionesList';
import OracionesEditor from 'components/admin/Oraciones/OracionesEditor';
import MaximasList from 'components/admin/Maximas/MaximasList';
import MaximasEditor from 'components/admin/Maximas/MaximasEditor';
import MultimediaList from 'components/admin/Multimedia/MultimediaList';
import MultimediaEditor from 'components/admin/Multimedia/MultimediaEditor';
import CelebracionesList from 'components/admin/Celebraciones/CelebracionesList';
import CelebracionesEditor from 'components/admin/Celebraciones/CelebracionesEditor';
import MisasEditor from 'components/admin/Misas/MisasEditor';
import MisasList from 'components/admin/Misas/MisasList';
import AcuerdosCatetegoryList from 'components/admin/Acuerdos/AcuerdosCatetegoryList';
import AcuerdosCategoryEditor from 'components/admin/Acuerdos/AcuerdosCategoryEditor';
import AcuerdosList from 'components/admin/Acuerdos/AcuerdosList';
import AcuerdosEditor from 'components/admin/Acuerdos/AcuerdosEditor';
import ConstitucionesCatetegoryList from 'components/admin/Constituciones/ConstitucionesCatetegoryList';
import ConstitucionesCategoryEditor from 'components/admin/Constituciones/ConstitucionesCategoryEditor';
import ConstitucionesList from 'components/admin/Constituciones/ConsitucionesList';
import ConstitucionesEditor from 'components/admin/Constituciones/ConstitucionesEditor';
import DirectorioList from 'components/admin/Directorio/DirectorioList';
import DirectorioEditor from 'components/admin/Directorio/DirectorioEditor';
import Tests from 'components/tests';
import NotificacionesList from 'components/admin/Notificaciones/NotificacionesList';
import NotificacionesEditor from 'components/admin/Notificaciones/NotificacionesEditor';
import AccountEditor from 'components/admin/Account/AccountEditor';
import TextosLiturgicosList from 'components/admin/TextosLiturgicos/TextosLiturgicosList';
import TextosLiturgicosEditor from 'components/admin/TextosLiturgicos/TextosLiturgicosEditor';
import ConfigurarAnoLiturgicoList from 'components/admin/ConfigurarAnoLiturgico/ConfigurarAnoLiturgicoList';

const cookies = new Cookies();

export default function AppRoutes() {
  
  

  
  return (
    <Router>
      <Routes>
        
        {/* USERS */}
        <Route path="/admin/usuarios" element={<UserList />}/>
        <Route path="/admin/usuarios/add" element={<UserEditor />}/>
        <Route path="/admin/usuarios/edit/:user_id" element={<UserEditor />}/>
        

        {/* ADMINS */}
        <Route path="/admin/administradores" element={<AdminList />}/>
        <Route path="/admin/administradores/add" element={<AdminEditor />}/>
        <Route path="/admin/administradores/edit/:user_id" element={<AdminEditor />}/>

        {/* MENU */}
        <Route path="/admin/menu" element={<MenuList />}/>

        {/* SANTORAL */}
        <Route path="/admin/santoral" element={<SantoralList />}/>
        <Route path="/admin/santoral/add" element={<SantoralEditor />}/>
        <Route path="/admin/santoral/edit/:santoral_id" element={<SantoralEditor />}/>

        {/* FIESTAS MOVIBLES Y PROPIAS */}
        <Route path="admin/fiestas" element={<FiestasList />}/>
        <Route path="admin/fiestas/add" element={<FiestasEditor />}/>
        <Route path="admin/fiestas/edit/:fiesta_id" element={<FiestasEditor />}/>


        {/* ANIVERSARIOS */}
        <Route path="/admin/aniversarios" element={<AniversariosList />}/>
        <Route path="/admin/aniversarios/add" element={<AniversariosEditor />}/>
        <Route path="/admin/aniversarios/edit/:aniversario_id" element={<AniversariosEditor />}/>


        {/* ORACIONES */}
        <Route path="/admin/oraciones" element={<OracionesList />}/>
        <Route path="/admin/oraciones/add" element={<OracionesEditor />}/>
        <Route path="/admin/oraciones/edit/:oracion_id" element={<OracionesEditor />}/>

        {/* MAXIMAS */}
        <Route path="/admin/maximas" element={<MaximasList />}/>
        <Route path="/admin/maximas/add" element={<MaximasEditor />}/>
        <Route path="/admin/maximas/edit/:maxima_id" element={<MaximasEditor />}/>

        {/* MULTIMEDIA */}
        <Route path="/admin/multimedia" element={<MultimediaList  />}/>
        <Route path="/admin/multimedia/add" element={<MultimediaEditor />}/>
        <Route path="/admin/multimedia/edit/:multimedia_id" element={<MultimediaEditor />}/>

        {/* CELEBRACIONES */}
        <Route path="/admin/celebraciones" element={<CelebracionesList />}/>
        <Route path="/admin/celebraciones/add" element={<CelebracionesEditor />}/>
        <Route path="/admin/celebraciones/edit/:celebracion_id" element={<CelebracionesEditor />}/>
        
        {/* TEXTOS LITÚRGICOS */}
        <Route path="/admin/textos_liturgicos" element={<TextosLiturgicosList />}/>
        <Route path="/admin/textos_liturgicos/add" element={<TextosLiturgicosEditor />}/>
        <Route path="/admin/textos_liturgicos/edit/:texto_liturgico_id" element={<TextosLiturgicosEditor />}/>
        
        {/* MISAS */}
        <Route path="/admin/misas" element={<MisasList />}/>
        <Route path="/admin/misas/add" element={<MisasEditor />}/>
        <Route path="/admin/misas/edit/:misa_id" element={<MisasEditor />}/>

        {/* ACUERDOS CATEGORIAS */}
        <Route path="/admin/acuerdos/categorias" element={<AcuerdosCatetegoryList  />}/>
        <Route path="/admin/acuerdos/categorias/add" element={<AcuerdosCategoryEditor />}/>
        <Route path="/admin/acuerdos/categorias/edit/:acuerdos_category_id" element={<AcuerdosCategoryEditor />}/>

        {/* ACUERDOS */}
        <Route path="/admin/acuerdos/:acuerdos_category_id" element={<AcuerdosList />}/>
        <Route path="/admin/acuerdos/:acuerdos_category_id/add" element={<AcuerdosEditor  />}/>
        <Route path="/admin/acuerdos/:acuerdos_category_id/edit/:acuerdo_id" element={<AcuerdosEditor />}/>

        {/* CONSTITUCIONES CATEGORIAS */}
        <Route path="/admin/constituciones/categorias" element={<ConstitucionesCatetegoryList />}/>
        <Route path="/admin/constituciones/categorias/add" element={<ConstitucionesCategoryEditor />}/>
        <Route path="/admin/constituciones/categorias/edit/:constituciones_category_id" element={<ConstitucionesCategoryEditor />}/>

        {/* DIRECTORIO */}
        <Route path="/admin/directorio" element={<DirectorioList />}/>
        <Route path="/admin/directorio/add" element={<DirectorioEditor />}/>
        <Route path="/admin/directorio/edit/:directorio_id" element={<DirectorioEditor />}/>
          
        {/* CONSTITUCIONES */}
        <Route path="/admin/constituciones/:constituciones_category_id" element={<ConstitucionesList />}/>
        <Route path="/admin/constituciones/:constituciones_category_id/add" element={<ConstitucionesEditor  />}/>
        <Route path="/admin/constituciones/:constituciones_category_id/edit/:constitucion_id" element={<ConstitucionesEditor />}/>

        {/* INGRESOS */}
        <Route path="/admin/ingresos" element={<IngresosList />}/>


        {/* NOTIFICACIONES */}
        <Route path="/admin/notificaciones" element={<NotificacionesList />}/>
        <Route path="/admin/notificaciones/add" element={<NotificacionesEditor />}/>
        <Route path="/admin/notificaciones/edit/:notificacion_id" element={<NotificacionesEditor />}/>
        
        {/* NOTIFICACIONES */}
        <Route path="/admin/configurar_ano_liturgico" element={<ConfigurarAnoLiturgicoList />}/>
        
        {/* MY ACCOUNT */}
        <Route path="/account" element={<AccountEditor />}/>
        
        {/* TESTS */}
        <Route path="/tests" element={<Tests />}/>



        
      </Routes>
    </Router>
  );
}
