import react, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';

import App from 'elements/HtmlEditor/App';
import Box from 'elements/Box';
import { ProjectConfig } from 'Global';

import 'assets/css/main.css';

import { TextosLiturgicos, fakeAPIEditor } from './TextosLiturgicosList';
import axios from 'axios';

const defaultValues ={
    texto_liturgico_id: 'null',
    tiempo: "tiempo_ordinario",
    dia: "6",
    semana: 1,
    color: "Verde",
    oficio_lectura: null,
    laudes: null,
    horas_intermedias: null,
    visperas: null,
    completas: null,

    lecturas_ano_1: null,
    reflexiones_ano_1: null,
    lectio_divina_ano_1: null,

    lecturas_ano_2: null,
    reflexiones_ano_2: null,
    lectio_divina_ano_2: null,

    lecturas_dominical_a: null,
    reflexiones_dominical_a: null,
    lectio_divina_dominical_a: null,

    lecturas_dominical_b: null,
    reflexiones_dominical_b: null,
    lectio_divina_dominical_b: null,
    
    lecturas_dominical_c: null,
    reflexiones_dominical_c: null,
    lectio_divina_dominical_c: null

}

// 'reflexiones_dominical_a' => $reflexiones_dominical_a,
// 'lectio_divina_dominical_a' => $lectio_divina_dominical_a,

export default function TextosLiturgicosEditor ( props: any ) {

    const semanaArray = Array.from({length: 35}, (_, i) => i + 1);

    const navigate = useNavigate();

    const { texto_liturgico_id = 'null' } = useParams();

    const [formValues, setFormValues] = useState<any>({...defaultValues, texto_liturgico_id: texto_liturgico_id});

    const [oficio, setOficio] = useState<any>(null);
    const [laudes, setLaudes] = useState<any>(null);
    const [horas, setHoras] = useState<any>(null);
    const [visperas, setVisperas] = useState<any>(null);
    const [completas, setCompletas] = useState<any>(null);

    const [lecturasAño1, setLecturasAño1] = useState<any>(null);
    const [lecturasAño1reflexiones, setLecturasAño1reflexiones] = useState<any>(null);
    const [lecturasAño1lectio_divina, setLecturasAño1lectio_divina] = useState<any>(null);

    const [lecturasAño2, setLecturasAño2] = useState<any>(null);
    const [lecturasAño2reflexiones, setLecturasAño2reflexiones] = useState<any>(null);
    const [lecturasAño2lectio_divina, setLecturasAño2lectio_divina] = useState<any>(null);

    const [dominicalAñoA, setDominicalAñoA] = useState<any>(null);
    const [dominicalAñoAreflexiones, setDominicalAñoAreflexiones] = useState<any>(null);
    const [dominicalAñoAlectio_divina, setDominicalAñoAlectio_divina] = useState<any>(null);

    const [dominicalAñoB, setDominicalAñoB] = useState<any>(null);
    const [dominicalAñoBreflexiones, setDominicalAñoBreflexiones] = useState<any>(null);
    const [dominicalBñoBlectio_divina, setDominicalAñoBAlectio_divina] = useState<any>(null);

    const [dominicalAñoC, setDominicalAñoC] = useState<any>(null);
    const [dominicalAñoCreflexiones, setDominicalAñoCreflexiones] = useState<any>(null);
    const [dominicalAñoClectio_divina, setDominicalAñoCAlectio_divina] = useState<any>(null);


    
    useEffect (() => {
        if (texto_liturgico_id !== 'null') {
            loadData();
        }
        
    }, []);

    const loadData = () => {
        axios.get(ProjectConfig.api_url, {
            params: {
                request: 'textos_liturgicos_details',
                texto_liturgico_id: texto_liturgico_id
            }
        })
        .then(function (response) {
            console.log(response.data);
            setFormValues(response.data);

            setOficio(response.data.oficio_lectura);
            setLaudes(response.data.laudes);
            setHoras(response.data.horas_intermedias);
            setVisperas(response.data.visperas);
            setCompletas(response.data.completas);

            setLecturasAño1(response.data.lecturas_ano_1);
            setLecturasAño1reflexiones(response.data.reflexiones_ano_1);
            setLecturasAño1lectio_divina(response.data.lectio_divina_ano_1);

            setLecturasAño2(response.data.lecturas_ano_2);
            setLecturasAño2reflexiones(response.data.reflexiones_ano_2);
            setLecturasAño2lectio_divina(response.data.lectio_divina_ano_2);


            setDominicalAñoA(response.data.lecturas_dominical_a);
            setDominicalAñoAreflexiones(response.data.reflexiones_dominical_a);
            setDominicalAñoAlectio_divina(response.data.lectio_divina_dominical_a);

            setDominicalAñoB(response.data.lecturas_dominical_b);
            setDominicalAñoBreflexiones(response.data.reflexiones_dominical_b);
            setDominicalAñoBAlectio_divina(response.data.lectio_divina_dominical_b);

            setDominicalAñoC(response.data.lecturas_dominical_c);
            setDominicalAñoCreflexiones(response.data.reflexiones_dominical_c);
            setDominicalAñoCAlectio_divina(response.data.lectio_divina_dominical_c);
            
        })
        .catch(function (error) {
            console.log(error);
        });
    }


    const handleOficioChange = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, oficio_lectura: jsonContent});
    }

    const handleLaudesChange = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, laudes: jsonContent});
    }

    const handleHorasChange = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, horas_intermedias: jsonContent});
    }

    const handleVisperasChange = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, visperas: jsonContent});
    }

    const handleCompletasChange = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, completas: jsonContent});
    }

    // AÑO1

    const handleLecturasAño1Change = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, lecturas_ano_1: jsonContent});
    }
    const handleLecturasAño1ChangeReflexiones = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, reflexiones_ano_1: jsonContent});
        console.log(jsonContent)
    }
    const handleLecturasAño1ChangeLeticio = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, lectio_divina_ano_1: jsonContent});
    }

    // AÑO 2

    const handleLecturasAño2Change = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, lecturas_ano_2: jsonContent});
    }

    const handleLecturasAño2ChangeReflexiones = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, reflexiones_ano_2: jsonContent});
    }
    const handleLecturasAño2ChangeLeticio = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, lectio_divina_ano_2: jsonContent});
    }

    // DOMINICAL A

    const handleDominicalAñoAChange = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, lecturas_dominical_a: jsonContent});
    }
    const handleDominicalAñoAChangeReflexiones = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, reflexiones_dominical_a: jsonContent});
    }
    const handleDominicalAñoAChangeLeticio = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, lectio_divina_dominical_a: jsonContent});
    }

    // DOMINICAL B
    const handleDominicalAñoBChange = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, lecturas_dominical_b: jsonContent});
    }

    const handleDominicalAñoBChangeReflexiones = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, reflexiones_dominical_b: jsonContent});
    }
    const handleDominicalAñoBChangeLeticio = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, lectio_divina_dominical_b: jsonContent});
    }
    
    // DOMINICAL C

    const handleDominicalAñoCChange = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, lecturas_dominical_c: jsonContent});
    }

    const handleDominicalAñoCChangeReflexiones = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, reflexiones_dominical_c: jsonContent});
    }
    const handleDominicalAñoCChangeLeticio = (htmlContent: any, jsonContent: any) => {
        setFormValues({...formValues, lectio_divina_dominical_c: jsonContent});
    }

    

    const handleSubmit = () => {

        axios.post(ProjectConfig.api_url, formValues, {
            params: {
                request: 'add_edit_textos_liturgicos'
            }
        })
        .then(function (response) {
            console.log(response);
            navigate('/admin/textos_liturgicos');
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {});
    }

    return (
        <>
            <div id="textosLiturgicosForm">
                <div className="textosLiturgicosForm_right">
                    <Box id="textos_liturgicos_editor" className="box_editor" title="Texto litúrgico" subtitle="Editor">
                        <div className='input_group'>
                            <div className='input_column'>
                                <TextField
                                    id="tiempo"
                                    name="tiempo"
                                    label="Tiempo"
                                    type="text"
                                    required
                                    select
                                    fullWidth
                                    size="small"
                                    value={formValues.tiempo}
                                    onChange={(e) => setFormValues({...formValues, tiempo: e.target.value})}
                                >
                                    <MenuItem value="tiempo_ordinario">Tiempo ordinario</MenuItem>
                                    <MenuItem value="cuaresma">Cuaresma</MenuItem>
                                    <MenuItem value="semana_santa">Semana Santa</MenuItem>
                                    <MenuItem value="pascua">Pascua</MenuItem>
                                    <MenuItem value="adviento">Adviento</MenuItem>
                                    <MenuItem value="navidad">Navidad</MenuItem>
                                </TextField>

                                <TextField
                                    id="dia"
                                    name="dia"
                                    label="Día"
                                    type="text"
                                    required
                                    select
                                    fullWidth
                                    size="small"
                                    value={formValues.dia}
                                    onChange={(e) => setFormValues({...formValues, dia: e.target.value})}
                                    >
                                    <MenuItem value="0">Domingo</MenuItem>
                                    <MenuItem value="1">Lunes</MenuItem>
                                    <MenuItem value="2">Martes</MenuItem>
                                    <MenuItem value="3">Miércoles</MenuItem>
                                    <MenuItem value="4">Jueves</MenuItem>
                                    <MenuItem value="5">Viernes</MenuItem>
                                    <MenuItem value="6">Sábado</MenuItem>
                                </TextField>

                                <TextField
                                    id="semana"
                                    name="semana"
                                    label="Semana"
                                    type="text"
                                    required
                                    select
                                    fullWidth
                                    size="small"
                                    value={formValues.semana}
                                    onChange={(e) => setFormValues({...formValues, semana: e.target.value})}
                                    >
                                    {semanaArray.map( (item,index) => (
                                        <MenuItem key={index} value={index+1}>{index+1}</MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    id="color"
                                    name="color"
                                    label="Color"
                                    type="text"
                                    required
                                    select
                                    fullWidth
                                    size="small"
                                    value={formValues.color}
                                    onChange={(e) => {
                                        setFormValues({...formValues, color: e.target.value});
                                    }}
                                >
                                    <MenuItem value="Verde">Verde</MenuItem>
                                    <MenuItem value="Blanco">Blanco</MenuItem>
                                    <MenuItem value="Morado">Morado</MenuItem>
                                    <MenuItem value="Rojo">Rojo</MenuItem> 
                                </TextField>
                            </div>



                            <div className='input_row'>
                                <div className='input_label_group'>
                                    <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Oficio de lectura
                                    </Typography>

                                    <App
                                        handleEditorChange={handleOficioChange}
                                        content={oficio}
                                        placeholder=""
                                        />
                                </div>

                                <div className='input_label_group'>
                                    <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Laudes
                                    </Typography>

                                    <App
                                        handleEditorChange={handleLaudesChange}
                                        content={laudes}
                                        placeholder=""
                                        />
                                </div>
                                
                                <div className='input_label_group'>
                                    <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Horas intermedias
                                    </Typography>

                                    <App
                                        handleEditorChange={handleHorasChange}
                                        content={horas}
                                        placeholder=""
                                        />
                                </div>
                                
                                <div className='input_label_group'>
                                    <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Vísperas
                                    </Typography>

                                    <App
                                        handleEditorChange={handleVisperasChange}
                                        content={visperas}
                                        placeholder=""
                                        />
                                </div>

                                <div className='input_label_group'>
                                    <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Completas
                                    </Typography>

                                    <App
                                        handleEditorChange={handleCompletasChange}
                                        content={completas} 
                                        placeholder=""
                                        />
                                </div>

                                <div className='input_label_group'>
                                    <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Lecturas año 1
                                    </Typography>

                                    <App
                                        handleEditorChange={handleLecturasAño1Change}
                                        content={lecturasAño1}
                                        placeholder=""
                                        />
                                        <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Reflexiones año 1
                                    </Typography>
                                    <App
                                        handleEditorChange={handleLecturasAño1ChangeReflexiones}
                                        content={lecturasAño1reflexiones}
                                        placeholder=""
                                        />
                                        <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Lectio divina año 1
                                    </Typography>
                                    <App
                                        handleEditorChange={handleLecturasAño1ChangeLeticio}
                                        content={lecturasAño1lectio_divina}
                                        placeholder=""
                                        />
                                </div>
                                
                                <div className='input_label_group'>
                                    <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Lecturas año 2
                                    </Typography>

                                    <App
                                        handleEditorChange={handleLecturasAño2Change}
                                        content={lecturasAño2}
                                        placeholder=""
                                        />
                                                                            <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Reflexiones año 2
                                    </Typography>
                                    <App
                                        handleEditorChange={handleLecturasAño2ChangeReflexiones}
                                        content={lecturasAño2reflexiones}
                                        placeholder=""
                                        />
                                        <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Lectio divina año 2
                                    </Typography>
                                    <App
                                        handleEditorChange={handleLecturasAño2ChangeLeticio}
                                        content={lecturasAño2lectio_divina}
                                        placeholder=""
                                        />
                                </div>

                                <div className='input_label_group'>
                                    <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Lectura dominical año A
                                    </Typography>

                                    <App
                                        handleEditorChange={handleDominicalAñoAChange}
                                        content={dominicalAñoA}
                                        placeholder=""
                                        />

                                        <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Reflexiones año A
                                    </Typography>
                                    <App
                                        handleEditorChange={handleDominicalAñoAChangeReflexiones}
                                        content={dominicalAñoAreflexiones}
                                        placeholder=""
                                        />
                                        <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Lectio divina año A
                                    </Typography>
                                    <App
                                        handleEditorChange={handleDominicalAñoAChangeLeticio}
                                        content={dominicalAñoAlectio_divina}
                                        placeholder=""
                                        />
                                </div>

                                <div className='input_label_group'>
                                    <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Lectura dominical año B
                                    </Typography>

                                    <App
                                        handleEditorChange={handleDominicalAñoBChange}
                                        content={dominicalAñoB}
                                        placeholder=""
                                        />
                                        <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Reflexiones año B
                                    </Typography>
                                    <App
                                        handleEditorChange={handleDominicalAñoBChangeReflexiones}
                                        content={dominicalAñoBreflexiones}
                                        placeholder=""
                                        />
                                        <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Lectio divina año B
                                    </Typography>
                                    <App
                                        handleEditorChange={handleDominicalAñoBChangeLeticio}
                                        content={dominicalBñoBlectio_divina}
                                        placeholder=""
                                        />
                                </div>
                                
                                <div className='input_label_group'>
                                    <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Lectura dominical año C
                                    </Typography>

                                    <App
                                        handleEditorChange={handleDominicalAñoCChange}
                                        content={dominicalAñoC}
                                        placeholder=""
                                        />
                                        <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Reflexiones año C
                                    </Typography>
                                    <App
                                        handleEditorChange={handleDominicalAñoCChangeReflexiones}
                                        content={dominicalAñoCreflexiones}
                                        placeholder=""
                                        />
                                        <Typography
                                        variant="body1"
                                        sx={{fontWeight: '500', mb: '10px', mt: '5px'}}
                                    >
                                        Lectio divina año C
                                    </Typography>
                                    <App
                                        handleEditorChange={handleDominicalAñoCChangeLeticio}
                                        content={dominicalAñoClectio_divina}
                                        placeholder=""
                                        />
                                </div>
                            </div>
                        </div> 
                
                        <div className='buttons'>
                            <Button
                                onClick={() => {
                                    navigate('/admin/textos_liturgicos', { replace: true })
                                }}
                                variant="outlined"
                                className="button_2"
                            >
                                Cancelar
                            </Button>
                            
                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                className="button_1"
                            >
                                Guardar
                            </Button>
                        </div>
                    </Box>
                </div>
            </div>
        </>
    );
}