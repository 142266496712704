import { Button, IconButton, TextareaAutosize, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import App from "elements/HtmlEditor/App";
import { useSnackbar, withSnackbar } from "notistack";



export default withSnackbar(
  function ConstitucionesEditor(props: any) {

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    /*=======================================
      GET  ID FROM URL
    =======================================*/
    let { constituciones_category_id, constitucion_id } = useParams();
    /*=======================================
    =======================================*/


    const navigate = useNavigate();
    const defaultValues: any = {
      constitucion_id: constitucion_id ? constitucion_id : "null",
      constituciones_category_id: constituciones_category_id,
      title: "",
      contentHTML: "",
      contentJSON: null,
    };

    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState(defaultValues)
    const [files, setFiles] = useState<any>([]);




    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
      const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: value,
      });
    };




    /*=======================================
      TEXT EDITOR
    =======================================*/
    const [initialContent, setInitialContent] = useState<any>(null);

    const handleEditorChange = (htmlContent: any, jsonContent: any) => {
      setFormValues({
        ...formValues,
        contentHTML: htmlContent,
        contentJSON: jsonContent
      });
    }
    /*=======================================
    =======================================*/




    /*=======================================
      GET DETAILS IF EDITING
    =======================================*/
    useEffect(() => {
      if (constitucion_id) {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "constituciones_details",
              id: constitucion_id,
            },
          })
          .then((response) => {
            console.log("formValues", response.data)
            setFormValues(response.data);
            setInitialContent(response.data.contentJSON);
          })
          .catch((err) => {
            console.log(err.data);
          });
      }
    }, []);
    /*=======================================
    =======================================*/




    /*=======================================
      SUBMIT
    =======================================*/
    const [progress, setProgress] = useState(0);



    const handleSubmit = () => {
      console.log(formValues);
      //FILES NEEDS TO BE UPLOADED USING FORMDATA, APART FROM THE OTHER FIELDS
      const formData = new FormData();
      formData.append("formValues", JSON.stringify(formValues));

      //AXIOS
      axios
        .post(ProjectConfig.api_url, formData, {
          onUploadProgress: (progressEvent) => {
            setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );

          },
          params: {
            request: "add_edit_constituciones",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data) {
            navigate("/admin/constituciones/" + constituciones_category_id);
          } else {
            enqueueSnackbar("Error al guardar", { variant: "error" });
          }
        })
        .catch((err) => {
          console.log(err.data);
        });


    }
    /*====================================
    =====================================*/

    interface SubCategoria {
      id: number;
      padre_id: number;
      label: string;
      titulo: string;
      contentJSON: any | null; 
      contentJSON_send: any | null; 
      contentHTML: string;
      contentHTML_send: string;
      estado: string;
      delete: number;
    }

    interface Categoria {
      id: number;
      label: string;
      titulo: string;
      contentJSON: any | null; 
      contentJSON_send: any | null; 
      contentHTML: string;
      contentHTML_send: string;
      subCategorias: SubCategoria[];
      estado: string;
      delete: number;
    }


    const [categoriasAll, setCategoriasAll] = useState<Categoria[]>([]);

    const [ident, setident] = useState(100000)
    const [identSub, setidentSub] = useState(200000)

    const handleEditorChangeNew = (htmlContent: any, jsonContent: any, id: any) => {

      actualizarCamposPorId(id, jsonContent, htmlContent)
    }

    const actualizarCamposPorId = (id: any, nuevoContentJSON: any, nuevoContentHTML: any) => {
      setCategoriasAll(prevCategorias =>
        prevCategorias.map(categoria =>
          categoria.id === id
            ? {
              ...categoria,
              contentJSON_send: nuevoContentJSON,
              contentHTML_send: nuevoContentHTML
            }
            : categoria
        )
      );


    };

    const handleEditorChangeNewSub = (categoria_id: any, sucategoria_id: any, htmlContent: any, jsonContent: any) => {
      actualizarCamposSubCategoriaPorId(categoria_id, sucategoria_id, jsonContent, htmlContent)
    }

    const actualizarCamposSubCategoriaPorId = (
      categoriaId: any,
      subCategoriaId: any,
      nuevoContentJSON: any,
      nuevoContentHTML: any
    ) => {
      setCategoriasAll(prevCategorias =>
        prevCategorias.map(categoria =>
          categoria.id === categoriaId
            ? {
              ...categoria,
              subCategorias: categoria.subCategorias.map(subCategoria =>
                subCategoria.id === subCategoriaId
                  ? {
                    ...subCategoria,
                    contentJSON_send: nuevoContentJSON,
                    contentHTML_send: nuevoContentHTML,
                  }
                  : subCategoria
              ),
            }
            : categoria
        )
      );
      // console.log(categoriasAll)
    };

    const insertarSubCategoriaPorId = (categoriaId: any, padre_id: any, nuevaSubCategoria: any) => {
      setCategoriasAll(prevCategorias =>
        prevCategorias.map(categoria =>
          categoria.id === categoriaId
            ? {
              ...categoria,
              subCategorias: [
                ...categoria.subCategorias,
                { ...nuevaSubCategoria, padre_id: padre_id }
              ]
            }
            : categoria
        )
      );

      setidentSub(identSub + 1);
    };


    const borrarSubCategoriaPorId = (categoriaId: any, subCategoriaId: any) => {

      borrarCategoriaPost(subCategoriaId)
      setCategoriasAll(prevCategorias =>
        prevCategorias.map(categoria =>
          categoria.id === categoriaId
            ? {
              ...categoria,
              subCategorias: categoria.subCategorias.filter(
                subCategoria => subCategoria.id !== subCategoriaId
              )
            }
            : categoria
        )
      );


    };


    const borrarCategoriaPorId = (id: number) => {

      borrarCategoriaPost(id)
      setCategoriasAll(prevCategorias =>
        prevCategorias.filter(categoria => categoria.id !== id)
      );
    };

    const borrarCategoriaPost = (categoria_id: number) => {
      const formData = new FormData();

      axios
        .post(ProjectConfig.api_url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
          params: {
            request: "categoria_delete",
            categoria_id: categoria_id
          },
        })
        .then((response) => {
          console.log(response);

        })
        .catch((err) => {
          console.log(err.data);
        });
    };




    const nuevaSubCategoria = {
      id: identSub,
      padre_id: null,
      label: 'Nueva Subcategoría',
      titulo: '',

      contentJSON: null,
      contentHTML: '',
      estado: 'nuevo',
      delete: 1
    };

    const handleSubCategoriaInputChange = (categoria_id: any, subcategoria_id: any, value: any) => {
      actualizarTituloSubCategoriaPorId(categoria_id, subcategoria_id, value)
    };
    const handleCategoriaInputChange = (id: any, value: any) => {
      actualizarTituloPorId(id, value)

      // 
    };

    const actualizarTituloPorId = (id: any, titulo: any) => {
      setCategoriasAll(prevCategorias =>
        prevCategorias.map(categoria =>
          categoria.id === id
            ? {
              ...categoria,
              titulo: titulo,
            }
            : categoria
        )
      );
    };

    const actualizarTituloSubCategoriaPorId = (categoriaId: any, subCategoriaId: any, titulo: string) => {
      setCategoriasAll(prevCategorias =>
        prevCategorias.map(categoria =>
          categoria.id === categoriaId
            ? {
              ...categoria,
              subCategorias: categoria.subCategorias.map(subCategoria =>
                subCategoria.id === subCategoriaId
                  ? {
                    ...subCategoria,
                    titulo: titulo,
                  }
                  : subCategoria
              ),
            }
            : categoria
        )
      );

      // console.log(categoriasAll)
    };





    const add_categoria = () => {


      setCategoriasAll(current => [...current, {
        id: ident,
        padre_id: null,
        label: 'NUEVA CATEGORIA',
        titulo: '',
        contentJSON: null,
        contentJSON_send: null,
        contentHTML: "",
        contentHTML_send: "",
        subCategorias: [{
          id: 0,
          padre_id: ident,
          label: 'NUEVA CATEGORIA',
          titulo: '',
          contentJSON: null,
          contentJSON_send: null,
          contentHTML: "hidden",
          contentHTML_send: "",
          estado: 'nuevo',
          delete: 1
        }],
        estado: 'nuevo',
        delete: 1
      }]);
      setident(ident + 1)


    }





    return (
      <div id="userForm">
        <div className="userForm_right">
          <Box id="santoral_editor" className="box_editor" title="Constitución" subtitle="Editar"  >



            <TextField
              id="title"
              name="title"
              label="Título"
              type="text"
              required
              fullWidth
              value={formValues.title}
              onChange={handleInputChange}
            />

            <App
              handleEditorChange={handleEditorChange} content={initialContent}
            />



            <div className='buttons'>
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancelar</Button>
              <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
            </div>
          </Box>
        </div>





      </div>

    );
  })