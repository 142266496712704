import React, { Component } from 'react'



export const ProjectConfig = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')?{
  // api_url: "http://desideria.localhost.com/api/",
  api_url: "https://desideriaapi.greenars.com.co/api/",
}
:
{
  api_url: "https://desideriaapi.manyanetcolombia.org/api/",
}