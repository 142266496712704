import React, { useState, useEffect } from "react"; // Importa useEffect aquí

import App from "./HtmlEditor/App";
import axios from "axios";
import { ProjectConfig } from "Global";

interface SubCategorias {
  id: number;
  padre_id: number;
  label: string;
  titulo: string;
  contentJSON: any | null;
  contentJSON_send: any | null;
  contentHTML: string;
  contentHTML_send: string;
  estado: string;
  delete: number;
  orden: number;
}

interface Categorias {
  id: number;
  label: string;
  titulo: string;
  contentJSON: any | null;
  contentJSON_send: any | null;
  contentHTML: string;
  contentHTML_send: string;
  subCategorias: SubCategorias[];
  estado: string;
  tipo: string;
  delete: number;
}

interface Props {
  categorias: Categorias[];
  setCategorias_send: (categorias: Categorias[]) => void;
}

export default function Categoria(props: Props) {
  const { categorias, setCategorias_send } = props;
  const [localCategorias, setLocalCategorias] = useState<Categorias[]>([]);
  const [inicio, setInicio] = useState<Boolean>(false);

  useEffect(() => {
    setLocalCategorias(categorias);
  }, [categorias]);

  useEffect(() => {
    setCategorias_send(localCategorias);
  }, [localCategorias]);

  const [ident, setident] = useState(100000);
  const [identSub, setidentSub] = useState(200000);
  const [max, setMax] = useState(200000);


  const [textView, settextView] = useState(false);
  const nuevaSubCategoria2 = {
    id: identSub,
    padre_id: null,
    label: "Nueva Subcategoría",
    titulo: "",

    contentJSON: null,
    contentHTML: "",
    contentJSON_send: null,
    contentHTML_send: "",
    estado: "nuevo",
    delete: 1,
  };

  const handleEditorChangeNew = (
    htmlContent: any,
    jsonContent: any,
    id: any
  ) => {
    actualizarCamposPorId(id, jsonContent, htmlContent);
  };

  const actualizarCamposPorId = (
    id: any,
    nuevoContentJSON: any,
    nuevoContentHTML: any
  ) => {
    setLocalCategorias((prevCategorias) =>
      prevCategorias.map((categoria) =>
        categoria.id === id
          ? {
              ...categoria,
              contentJSON_send: nuevoContentJSON,
              contentHTML_send: nuevoContentHTML,
            }
          : categoria
      )
    );

    setCategorias_send(localCategorias);
    
  };

  const borrarCategoriaPorId = (id: number) => {
    borrarCategoriaPost(id);
    setLocalCategorias((prevCategorias) =>
      prevCategorias.filter((categoria) => categoria.id !== id)
    );

    setCategorias_send(localCategorias);
  };
  const borrarCategoriaId = (id: number) => {
    setLocalCategorias((prevCategorias) =>
      prevCategorias.filter((categoria) => categoria.id !== id)
    );

    setCategorias_send(localCategorias);
  };

  const borrarCategoriaPost = (categoria_id: number) => {
    const formData = new FormData();
    formData.append("categorias", JSON.stringify(localCategorias));
    axios
      .post(ProjectConfig.api_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {},
        params: {
          request: "categoria_delete",
          categoria_id: categoria_id,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  const handleCategoriaInputChange = (id: any, value: any) => {
    actualizarTituloPorId(id, value);
  };

  const actualizarTituloPorId = (id: any, titulo: any) => {
    setLocalCategorias((prevCategorias) =>
      prevCategorias.map((categoria) =>
        categoria.id === id
          ? {
              ...categoria,
              titulo: titulo,
            }
          : categoria
      )
    );

    setCategorias_send(localCategorias);
  };

  const add_categoria = () => {
    borrarCategoriaId(0);
    setLocalCategorias((current) => [
      ...current,
      {
        id: ident,
        padre_id: null,
        label: "NUEVO TITULO",
        titulo: "",
        contentJSON: null,
        contentJSON_send: null,
        contentHTML: "",
        contentHTML_send: "",
        tipo: "Texto",
        subCategorias: [],
        estado: "nuevo",
        delete: 1,
      },
    ]);
    setident(ident + 1);
    settextView(true);

    setCategorias_send(localCategorias);
  };

  const handleEditorChangeNewSub = (
    categoria_id: any,
    sucategoria_id: any,
    htmlContent: any,
    jsonContent: any
  ) => {
    actualizarCamposSubCategoriaPorId(
      categoria_id,
      sucategoria_id,
      jsonContent,
      htmlContent
    );
  };

  const actualizarCamposSubCategoriaPorId = (
    categoriaId: any,
    subCategoriaId: any,
    nuevoContentJSON: any,
    nuevoContentHTML: any
  ) => {
    setLocalCategorias((prevCategorias) =>
      prevCategorias.map((categoria) =>
        categoria.id === categoriaId
          ? {
              ...categoria,
              subCategorias: categoria.subCategorias.map((subCategoria) =>
                subCategoria.id === subCategoriaId
                  ? {
                      ...subCategoria,
                      contentJSON_send: nuevoContentJSON,
                      contentHTML_send: nuevoContentHTML,
                    }
                  : subCategoria
              ),
            }
          : categoria
      )
    );

    setCategorias_send(localCategorias);
  };

  const handleSubCategoriaInputChange = (
    categoria_id: any,
    subcategoria_id: any,
    value: any
  ) => {
    actualizarTituloSubCategoriaPorId(categoria_id, subcategoria_id, value);
  };

  const actualizarTituloSubCategoriaPorId = (
    categoriaId: any,
    subCategoriaId: any,
    titulo: string
  ) => {
    setLocalCategorias((prevCategorias) =>
      prevCategorias.map((categoria) =>
        categoria.id === categoriaId
          ? {
              ...categoria,
              subCategorias: categoria.subCategorias.map((subCategoria) =>
                subCategoria.id === subCategoriaId
                  ? {
                      ...subCategoria,
                      titulo: titulo,
                    }
                  : subCategoria
              ),
            }
          : categoria
      )
    );

    setCategorias_send(localCategorias);
  };

  const insertarSubCategoriaPorId = (
    categoriaId: any,
    padre_id: any,
    nuevaSubCategoria: any,
    orden: any
  ) => {
    var ordenNew = parseInt(orden) + 1;

    setLocalCategorias((prevCategorias) =>
      prevCategorias.map((categoria) =>
        categoria.id === categoriaId
          ? {
              ...categoria,
              subCategorias: categoria.subCategorias.map((subCategoria) =>
                subCategoria.id > 1
                  ? {
                      ...subCategoria,
                      contentJSON: subCategoria.contentJSON_send,
                      contentHTML: subCategoria.contentHTML_send,
                    }
                  : subCategoria
              ),
            }
          : categoria
      )
    );

    setLocalCategorias((prevCategorias) =>
      prevCategorias.map((categoria) => {
        if (categoria.id === categoriaId) {
          const subCategorias = categoria.subCategorias || [];
          const subCategoriasActualizadas = [...subCategorias];

          const index = subCategorias.findIndex(
            (subCategoria) => subCategoria.orden === orden
          );

          if (index === -1) {
            subCategoriasActualizadas.push({
              ...nuevaSubCategoria,
              padre_id: padre_id,
              orden: ordenNew,
            });
          } else {
            subCategoriasActualizadas.splice(index + 1, 0, {
              ...nuevaSubCategoria,
              padre_id: padre_id,
              orden: ordenNew,
            });

            for (let i = index + 2; i < subCategoriasActualizadas.length; i++) {
              subCategoriasActualizadas[i].orden++;
            }
          }

          return {
            ...categoria,
            subCategorias: subCategoriasActualizadas,
          };
        } else {
          return categoria;
        }
      })
    );

    // actualizarOrdenSubCategoriaPorId(categoriaId, ordenNew);
    setCategorias_send(localCategorias);
    setidentSub(identSub + 1);
  };

  const borrarSubCategoriaPorId = (categoriaId: any, subCategoriaId: any) => {
    borrarCategoriaPost(subCategoriaId);
    setLocalCategorias((prevCategorias) =>
      prevCategorias.map((categoria) =>
        categoria.id === categoriaId
          ? {
              ...categoria,
              subCategorias: categoria.subCategorias.filter(
                (subCategoria) => subCategoria.id !== subCategoriaId
              ),
            }
          : categoria
      )
    );

    setCategorias_send(localCategorias);
  };

  return (
    <div>
      {localCategorias.map((categoria, index) => (
        <div key={index}>
          <label>
            {" "}
            <strong> CATEGORIA: </strong>
          </label>

          {categoria.id > 0 && (
            <>
              {categoria.delete == 1 && (
                <>
                  <label>{categoria.label}</label>
                  <div className="inputColumn">
                    <input
                      type="text"
                      name={"titulo_" + index}
                      id={"titulo_" + index}
                      onChange={(e) =>
                        handleCategoriaInputChange(categoria.id, e.target.value)
                      }
                      value={categoria.titulo}
                    />
                  </div>
                </>
              )}

              <label>{categoria.titulo}</label>
              <App
                placeholder=""
                handleEditorChange={(htmlContent: any, jsonContent: any) =>
                  handleEditorChangeNew(htmlContent, jsonContent, categoria.id)
                }
                content={categoria.contentJSON}
              />
              {categoria.delete == 1 && (
                <>
                  <button
                    onClick={() => borrarCategoriaPorId(categoria.id)}
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "8px",
                      fontSize: "14px",
                      padding: "8px 16px",
                      border: "none",
                      cursor: "pointer",
                      display: "block",
                      margin: "0 auto",
                    }}
                  >
                    Borrar Categoria
                  </button>
                </>
              )}

              <label>
                {" "}
                <strong> SUB CATEGORIAS </strong>
              </label>

              <br />

              {categoria.subCategorias.map((subcategoria, index2) => (
                <div key={index2}>
                  {subcategoria.id > 0 && (
                    <>
                      {subcategoria.delete == 1 && (
                        <>
                          <label>{subcategoria.label}</label>
                          <div className="inputColumn">
                            <input
                              type="text"
                              name={"titulo_sub_" + subcategoria.id}
                              id={"titulo_sub_" + subcategoria.id}
                              onChange={(e) =>
                                handleSubCategoriaInputChange(
                                  categoria.id,
                                  subcategoria.id,
                                  e.target.value
                                )
                              }
                              value={subcategoria.titulo}
                            />
                          </div>
                        </>
                      )}

                      <label>{subcategoria.titulo}</label>
                      <App
                        placeholder=""
                        handleEditorChange={(
                          htmlContent: any,
                          jsonContent: any
                        ) =>
                          handleEditorChangeNewSub(
                            categoria.id,
                            subcategoria.id,
                            htmlContent,
                            jsonContent
                          )
                        }
                        content={subcategoria.contentJSON}
                      />

                      {subcategoria.delete == 1 && (
                        <>
                          <button
                            onClick={() =>
                              borrarSubCategoriaPorId(
                                categoria.id,
                                subcategoria.id
                              )
                            }
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "8px",
                              fontSize: "14px",
                              padding: "8px 16px",
                              border: "none",
                              cursor: "pointer",
                              display: "block",
                              margin: "0 auto",
                            }}
                          >
                            Borrar
                          </button>
                        </>
                      )}
                      <div style={{ textAlign: "left" }}>
                        <button
                          onClick={() =>
                            insertarSubCategoriaPorId(
                              categoria.id,
                              categoria.id,
                              nuevaSubCategoria2,
                              subcategoria.orden
                            )
                          }
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            borderRadius: "8px",
                            fontSize: "14px",
                            padding: "8px 16px",
                            border: "none",
                            cursor: "pointer",
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        >
                          Agregar Subcategoria
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))}
              {categoria.subCategorias.length < 1 && (
                <>
                <button
                  onClick={() =>
                    insertarSubCategoriaPorId(
                      categoria.id,
                      categoria.id,
                      nuevaSubCategoria2,
                      -1
                    )
                  }
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    borderRadius: "8px",
                    fontSize: "14px",
                    padding: "8px 16px",
                    border: "none",
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                >
                  Agregar Subcategoria
                </button>
                </>
              )}
            </>
          )}
        </div>
      ))}

      <button
        onClick={add_categoria}
        style={{
          backgroundColor: "green",
          color: "white",
          borderRadius: "8px",
          fontSize: "14px",
          padding: "8px 16px",
          border: "none",
          cursor: "pointer",
          display: "block",
          margin: "0 auto",
        }}
      >
        {" "}
        Agregar Categoria{" "}
      </button>
    </div>
  );
}
