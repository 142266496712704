import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';



interface Props {
  name: any;
  image?: any;
  size?: any;
}

let name = 'N N';

export default function AppAvatar(props: Props) {

  const size = props.size ? props.size : 56;
  
    if(props.name){
      name = props.name;
    }
  
    

  //GET FIRST LETTER OF NAME IN UPPERCASE
  const firstLetter = name.charAt(0).toUpperCase();;
  //GET FIRST LETTER OF LAST NAME IF NAME HAS MORE THAN ONE WORD
  const secondLetter = name.split(' ').length > 1 ? name.split(' ')[1].charAt(0).toUpperCase(): '';

  return (
    <Avatar
      sx={{width: size, height: size}}
      children = {firstLetter+secondLetter}
      src={props.image}
    />
  );
}