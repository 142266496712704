import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ProjectConfig } from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import FiltersApplied from 'elements/Filters/FiltersApplied';

const MenuList = () => {

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);



  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {

    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'menu_list'
        }
      })
      .then((response) => {
        console.log("ul ", response.data);
        setList(response.data)
        setTotalPages(response.data.total_pages)
        setLoading(true)

      })
      .catch((err) => {
        console.log(err.data);
      })
    localStorage.setItem('page', page.toString())

  }, [page, refreshList])
  /*=========================================================*/



  return (
    <div id='ProductList'>

      <div className='buttons_top'>


      </div>
      <div id="task_list_container" className="flex_container">

        <Box id='user_list' title="Menu" subtitle="Lista"  >
          {!loading && <div id='loader'><CircularProgress /></div>}
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((list_item, i) => (
                  <TableRow

                    key={i}
                    hover
                    role="checkbox"
                  >
                    <TableCell  >
                      <div className='flexWImage'>
                        {/* <img className='ListImage' src={list_item.image} /> */}
                        <div className="flexWImage_right">
                          {list_item.nombre}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      </div>
    </div>
  );
};



export default MenuList;