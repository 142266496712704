import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import MainMenu from './Menu'
import logo from './assets/img/logo.png';
import Cookies from 'universal-cookie';


import {BrowserRouter as Router , Route, Routes} from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack';

import Login from 'components/admin/Login/Login';
import AccountMenu from 'components/admin/Account/AccountMenu';
import AppRoutes from 'AppRoutes';

const cookies = new Cookies();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
    fontSize: 13,
    fontWeightLight: 400,
    fontWeightRegular: 300,
    fontWeightMedium: 300,
    fontWeightBold: 700,
  },
  palette: { 
    primary: {
      light: '#07A2BB',
      main: '#07A2BB',
      dark: '#07B9BB',
      contrastText: '#fff',
    },
    secondary: {
      light: '#048D81',
      main: '#707070',
      dark: '#c50000',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    background: {
      default: '#fafafa',
    },
  },
  
},

);


const Site = () => {
  const childRef = useRef();
  const [subMenu, setSubmenu] = useState("asd"); 
  const userIsLogged = cookies.get('ltkn') !== undefined;


    useEffect(() => {
        setSubmenu("")
    }
    , []);


    return (
    <ThemeProvider theme={theme} >
      <SnackbarProvider maxSnack={3}>
    
    {//  userIsLogged ? REDIRECT TO LOGIN
        !userIsLogged? (
          <Router>
            <Routes>
              <Route path="/*" element={<Login />} /> 
            </Routes>
          </Router>
          ) : (
            <>
    <div id='structure'>
      <aside id="menu">
        <div id='logo'>
          <a href='/'>
            <img src={logo}></img>
            
          </a>
          
        </div>
        <MainMenu />
      </aside>
      <div id="main-container"> 
        <div id='top_bar'>
            <div id='top_bar_left'></div>
            <div id='top_bar_right'><AccountMenu /></div>
        </div>
        <div id="main-content">
          <AppRoutes />
        </div>
      </div>
    </div>
    </>
        )}
    </SnackbarProvider>
    </ThemeProvider>
    );
  
}

// ========================================
 

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Site />
  </React.StrictMode>
);







