import {
  Button,
  IconButton,
  TextareaAutosize,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from "Global";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import EventIcon from "@mui/icons-material/Event";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import App from "elements/HtmlEditor/App";
import { esES } from "@mui/x-date-pickers";
import "moment/locale/es";
import { useSnackbar, withSnackbar } from "notistack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AttachFileIcon from "@mui/icons-material/AttachFile";

export default withSnackbar(function SantoralEditor(props: any) {
  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  /*=======================================
      GET PROJECT ID AND ESTIMATE ID FROM URL
    =======================================*/
  let { santoral_id } = useParams();
  /*=======================================
    =======================================*/

  const navigate = useNavigate();
  const defaultValues: any = {
    santoral_id: santoral_id ? santoral_id : "null",
    image: "",
    name: "",
    date: null,
    bioHTML: "",
    bioJSON: null,
    colorSelect: "",
    tienePropio: 0,
    bioHTML_primeras_visperas: "",
    bioJSON_primeras_visperas: null,
    bioHTML_oficio_lectura: "",
    bioJSON_oficio_lectura: null,
    bioHTML_laudes: "",
    bioJSON_laudes: null,
    bioHTML_horas_intermedias: "",
    bioJSON_horas_intermedias: null,
    bioHTML_visperas: "",
    bioJSON_visperas: null,
    bioHTML_completas: "",
    bioJSON_completas: null,
    bioHTML_lecturas: "",
    bioJSON_lecturas: null,
    bioHTML_oraciones: "",
    bioJSON_oraciones: null,
    bioHTML_calendario: "",
    bioJSON_calendario: null,
    color_id: 0,
    categoria: "",
    filePdf: "",
    file_id: 0,
  };

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const [files, setFiles] = useState<any>([]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
      TEXT EDITOR
    =======================================*/
  const [initialContent, setInitialContent] = useState<any>(null);
  const [initialContent1, setInitialContent1] = useState<any>(null);
  const [initialContent2, setInitialContent2] = useState<any>(null);
  const [initialContent3, setInitialContent3] = useState<any>(null);
  const [initialContent4, setInitialContent4] = useState<any>(null);
  const [initialContent5, setInitialContent5] = useState<any>(null);
  const [initialContent6, setInitialContent6] = useState<any>(null);
  const [initialContent7, setInitialContent7] = useState<any>(null);
  const [initialContent8, setInitialContent8] = useState<any>(null);
  const [initialContent9, setInitialContent9] = useState<any>(null);

  
  interface pdfData {
    file_id: any;
    file: any;
    name: string;
    estado: string;
  }

  const [filesPdf, setFilesPdf] = useState<pdfData[]>([]);

  const handleEditorChange = (htmlContent: any, jsonContent: any) => {
    setFormValues({
      ...formValues,
      bioHTML: htmlContent,
      bioJSON: jsonContent,
    });
  };
  const handleEditorChangeOraciones = (htmlContent: any, jsonContent: any) => {
    setFormValues({
      ...formValues,
      bioHTML_oraciones: htmlContent,
      bioJSON_oraciones: jsonContent,
    });
  };

  const handleEditorChangeCalendario = (htmlContent: any, jsonContent: any) => {
    setFormValues({
      ...formValues,
      bioHTML_calendario: htmlContent,
      bioJSON_calendario: jsonContent,
    });
  };

  const handleEditorChangeVisperas = (htmlContent: any, jsonContent: any) => {
    setFormValues({
      ...formValues,
      bioHTML_visperas: htmlContent,
      bioJSON_visperas: jsonContent,
    });
  };

  const handleEditorChangeOficio_lectura = (
    htmlContent: any,
    jsonContent: any
  ) => {
    setFormValues({
      ...formValues,
      bioHTML_oficio_lectura: htmlContent,
      bioJSON_oficio_lectura: jsonContent,
    });
  };

  const handleEditorChangeLaudes = (htmlContent: any, jsonContent: any) => {
    setFormValues({
      ...formValues,
      bioHTML_laudes: htmlContent,
      bioJSON_laudes: jsonContent,
    });
  };

  const handleEditorChangeHoras_intermedias = (
    htmlContent: any,
    jsonContent: any
  ) => {
    setFormValues({
      ...formValues,
      bioHTML_horas_intermedias: htmlContent,
      bioJSON_horas_intermedias: jsonContent,
    });
  };

  const handleEditorChangeCompletas = (htmlContent: any, jsonContent: any) => {
    setFormValues({
      ...formValues,
      bioHTML_completas: htmlContent,
      bioJSON_completas: jsonContent,
    });
  };

  const handleEditorChangeLecturas = (htmlContent: any, jsonContent: any) => {
    setFormValues({
      ...formValues,
      bioHTML_lecturas: htmlContent,
      bioJSON_lecturas: jsonContent,
    });
  };
  const handleEditorChangePrimeras_visperas = (
    htmlContent: any,
    jsonContent: any
  ) => {
    setFormValues({
      ...formValues,
      bioHTML_primeras_visperas: htmlContent,
      bioJSON_primeras_visperas: jsonContent,
    });
  };
  /*=======================================
    =======================================*/

  const [colores, setColores] = useState<any[]>([]);
  const [color_id, setColor_id] = useState("");

  /*=======================================
      GET DETAILS IF EDITING
    =======================================*/
  useEffect(() => {
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "all_colores",
        },
      })
      .then((response) => {
        // console.log(response.data)
        const coloresData = JSON.parse(response.data.colores);
        setColores(coloresData);
        
        // console.log(coloresData)
        // console.log(colores)
      })
      .catch((err) => {
        console.log(err.data);
      });

    if (santoral_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "santoral_details",
            id: santoral_id,
          },
        })
        .then((response) => {
          // console.log("formValues", response.data.colors)
          console.log(response.data);
          setFormValues(response.data);
          setInitialContent(response.data.bioJSON);
          setInitialContent1(response.data.bioJSON_primeras_visperas);
          setInitialContent2(response.data.bioJSON_oficio_lectura);
          setInitialContent3(response.data.bioJSON_laudes);
          setInitialContent4(response.data.bioJSON_horas_intermedias);
          setInitialContent5(response.data.bioJSON_visperas);
          setInitialContent6(response.data.bioJSON_completas);
          setInitialContent7(response.data.bioJSON_lecturas);
          setInitialContent8(response.data.bioJSON_oraciones);
          setInitialContent9(response.data.bioJSON_calendario);
          setFilesPdf(response.data.files);

          // setInitialContent7(response.data.bioJSON);
          setColor_id(response.data.color_id);
          // setFormValues({
          //   ...formValues,
          //   color_id: response.data.color_id,
          // });
        })
        .catch((err) => {
          console.log(err.data);
        });
    } else {
    }
  }, []);
  /*=======================================
    =======================================*/

  // //  lISTADO DE COLERES
  // const colorsList = [
  //   { value: "red", label: "Rojo" },
  //   { value: "green", label: "Verde" },
  //   { value: "blue", label: "Azul" },
  //   // Agrega más colores según sea necesario
  // ];

  /*=======================================
      SUBMIT
    =======================================*/
  const [progress, setProgress] = useState(0);

  const checkForm = (): boolean => {
    let errors = false;

    if (formValues.name == "") {
      enqueueSnackbar("El nombre es obligatorio", { variant: "error" });
      errors = true;
    }
    if (formValues.date == null) {
      enqueueSnackbar("La fecha es obligatoria", { variant: "error" });
      errors = true;
    }

    return errors;
  };

  const handleSubmit = () => {
    console.log(formValues.date);
    //FILES NEEDS TO BE UPLOADED USING FORMDATA, APART FROM THE OTHER FIELDS
    const formData = new FormData();
    formData.append("files", files);
    formData.append("formValues", JSON.stringify(formValues));
    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i]);
    }

    // Agregar archivos PDF al FormData
    for (let i = 0; i < filesPdf.length; i++) {
      formData.append(`filesPdf[${i}][file_id]`, filesPdf[i].file_id);
      formData.append(`filesPdf[${i}][file]`, filesPdf[i].file);
      formData.append(`filesPdf[${i}][name]`, filesPdf[i].name);
      formData.append(`filesPdf[${i}][estado]`, filesPdf[i].estado);
    }

    //AXIOS
    axios
      .post(ProjectConfig.api_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
        params: {
          request: "add_edit_santoral",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data) {
          navigate("/admin/santoral");
        } else {
          enqueueSnackbar("Error al guardar", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  /*====================================
    =====================================*/

  const borrar_file = (directorio_id: Number) => {
    const formData = new FormData();
    formData.append("archivo_id", JSON.stringify(directorio_id));
    //AXIOS
    axios
      .post(ProjectConfig.api_url, formData, {
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
        params: {
          request: "delete_archivo",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data) {
          setFormValues({
            ...formValues,
            filePdf: "",
            file_id: 0,
          });
        } else {
          enqueueSnackbar("Error al borrar", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const [ident, setident] = useState(1000000001);
  const add_file = () => {
    setFilesPdf((current) => [
      ...current,
      {
        file_id: ident,
        file: "",
        name: "",
        estado: "nuevo",
      },
    ]);
    setident(ident + 1);
  };

  const handleFileInputChange = (
    file_id: number,
    file: any,
    name: string,
    estado: string
  ) => {
    actualizarFile(file_id, file, name, estado);

    //
  };

  const actualizarFile = (
    file_id: number,
    file: any,
    name: string,
    estado: string
  ) => {
    if (estado == "nuevo") {
      setFilesPdf((prevFile) =>
        prevFile.map((pdfRow) =>
          pdfRow.file_id === file_id
            ? {
                ...pdfRow,
                file: file,
                name: name,
              }
            : pdfRow
        )
      );
    } else {
      setFilesPdf((prevFile) =>
        prevFile.map((pdfRow) =>
          pdfRow.file_id === file_id
            ? {
                ...pdfRow,
                file: file,
                name: name,
                estado: "editable",
              }
            : pdfRow
        )
      );
    }

    console.log(filesPdf);
  };

  const handleTituloInputChange = (id: any, value: any) => {
    actualizarTituloPorIdPdf(id, value);

    //
  };

  const actualizarTituloPorIdPdf = (file_id: any, name: any) => {
    setFilesPdf((prevFile) =>
      prevFile.map((pdfRow) =>
        pdfRow.file_id === file_id
          ? {
              ...pdfRow,
              name: name,
            }
          : pdfRow
      )
    );
  };

  const borrarPorIdPdf = (file_id: number) => {
    borrar_file(file_id);
    setFilesPdf((prevPdf) =>
      prevPdf.filter((file) => file.file_id !== file_id)
    );
  };

  console.log("filesPdf");
  console.log(filesPdf);

  return (
    <div id="userForm">
      <div className="userForm_right">
        <Box
          id="santoral_editor"
          className="box_editor"
          title="Santoral"
          subtitle="Editar"
        >
          <div className="input_group">
            <div className="input_group">
              <div className="input_row">
                {/* UPLOAD button with preview */}
                <div className="drawerImagesContainer">
                  <div
                    className="drawerImagesContainerDiv"
                    onDrop={(e) => {
                      e.preventDefault();
                      // UPDATE FILE
                      const files = e.dataTransfer.files;
                      setFiles(files[0]);
                      const fileArray = Array.from(files).map((file: any) =>
                        URL.createObjectURL(file)
                      );
                      setFormValues({
                        ...formValues,
                        image: fileArray[0],
                      });
                      e.currentTarget.classList.remove("dropzone_active");
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.currentTarget.classList.add("dropzone_active");
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      e.currentTarget.classList.remove("dropzone_active");
                    }}
                  >
                    {formValues.image && <img src={formValues.image} />}

                    <label
                      id="dropFileDiv"
                      className={formValues.image && "showOnlyOnHover"}
                    >
                      <IconButton
                        aria-label="delete"
                        size="small"
                        className="addFile_button"
                        component="span"
                      >
                        <CameraAltIcon />
                        <input
                          type="file"
                          multiple
                          hidden
                          onChange={(e) => {
                            const files = e.target.files;
                            if (files) {
                              // UPDATE FILE
                              setFiles(files[0]);
                              const fileArray = Array.from(files).map(
                                (file: any) => URL.createObjectURL(file)
                              );
                              setFormValues({
                                ...formValues,
                                image: fileArray[0],
                              });
                            }
                          }}
                        />
                      </IconButton>
                    </label>
                  </div>
                </div>

                <div className="inputColumn">
                  <TextField
                    id="name"
                    name="name"
                    label="Nombres"
                    type="text"
                    required
                    fullWidth
                    value={formValues.name}
                    onChange={handleInputChange}
                  />

                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    localeText={
                      esES.components.MuiLocalizationProvider.defaultProps
                        .localeText
                    }
                  >
                    <MobileDatePicker
                      label="Fecha"
                      views={["month", "day"]}
                      inputFormat="DD/MM"
                      value={formValues.date}
                      //hide year

                      openTo="month"
                      InputProps={{
                        endAdornment: <EventIcon />,
                      }}
                      showToolbar={false}
                      closeOnSelect
                      onChange={(newValue) => {
                        setFormValues({
                          ...formValues,
                          ["date"]: newValue.format("MM-DD"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            <App
              placeholder="Biografía"
              handleEditorChange={handleEditorChange}
              content={initialContent}
            />
            <label>Oraciones</label>
            <App
              placeholder="Oraciones"
              handleEditorChange={handleEditorChangeOraciones}
              content={initialContent8}
            />
            <label>Calendario propio</label>
            <App
              placeholder="Calendario propio"
              handleEditorChange={handleEditorChangeCalendario}
              content={initialContent9}
            />

            {/* Select de colores */}
            <div className="input_group">
              <label htmlFor="colorSelect">Selecciona un color:</label>
              <Select
                id="color"
                name="color"
                value={formValues.color_id}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    color_id: e.target.value, // Actualiza el estado color_id en formValues
                  });
                }}
              >
                {colores.map((color) => (
                  <MenuItem key={color.id} value={color.id}>
                    {color.nombre}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="input_group">
              <label htmlFor="categoria">Selecciona una Categoria:</label>
              <Select
                id="categoria"
                name="categoria"
                value={formValues.categoria}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    categoria: e.target.value, // Actualiza el estado color_id en formValues
                  });
                }}
              >
                <MenuItem key={""} value={""}>
                  {""}
                </MenuItem>
                <MenuItem key={"Fiesta"} value={"Fiesta"}>
                  {"Fiesta"}
                </MenuItem>
                <MenuItem key={"Memoria_libre"} value={"Memoria libre"}>
                  {"Memoria libre"}
                </MenuItem>
                <MenuItem
                  key={"Memoria_Obligatoria"}
                  value={"Memoria Obligatoria"}
                >
                  {"Memoria Obligatoria"}
                </MenuItem>
                <MenuItem key={"Solemnidad"} value={"Solemnidad"}>
                  {"Solemnidad"}
                </MenuItem>
              </Select>
            </div>

            {/* ¿TIENE PROPIO? */}
            <div className="checkbox_group">
              <label htmlFor="tienePropio">¿TIENE PROPIO?</label>
              <input
                type="checkbox"
                id="tiene_propio"
                checked={formValues.tienePropio === "1"} // Condición para marcar el checkbox
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    tienePropio: e.target.checked ? "1" : "0", // Almacena 1 si está marcado, 0 si no lo está
                  });
                }}
              />
            </div>

            {formValues.tienePropio != "0" && (
              <>
                <label>Primeras vísperas</label>
                <App
                  placeholder="Primeras vísperas"
                  handleEditorChange={handleEditorChangePrimeras_visperas}
                  content={initialContent1}
                />
                <label>Oficio de lectura</label>
                <App
                  placeholder="Oficio de lectura"
                  handleEditorChange={handleEditorChangeOficio_lectura}
                  content={initialContent2}
                />
                <label>Laudes</label>
                <App
                  placeholder="Laudes"
                  handleEditorChange={handleEditorChangeLaudes}
                  content={initialContent3}
                />
                <label>Horas intermedias</label>
                <App
                  placeholder="Horas intermedias"
                  handleEditorChange={handleEditorChangeHoras_intermedias}
                  content={initialContent4}
                />
                <label>Vísperas</label>
                <App
                  placeholder="Vísperas"
                  handleEditorChange={handleEditorChangeVisperas}
                  content={initialContent5}
                />
                <label>Completas</label>
                <App
                  placeholder="Completas"
                  handleEditorChange={handleEditorChangeCompletas}
                  content={initialContent6}
                />
                <label>Lecturas</label>
                <App
                  placeholder="Lecturas"
                  handleEditorChange={handleEditorChangeLecturas}
                  content={initialContent7}
                />
              </>
            )}
          </div>
          {filesPdf.length > 0 &&
            filesPdf.map((fileRow, index) => (
              <div key={index}>
                {/* <p>  Hola </p> */}
                {/* <p>  {fileRow.name} </p> */}
                <label>Titulo</label>
                <div className="inputColumn">
                  <input
                    type="text"
                    name={"titulo_" + index}
                    id={"titulo_" + index}
                    onChange={(e) =>
                      actualizarTituloPorIdPdf(fileRow.file_id, e.target.value)
                    }
                    value={fileRow.name}
                  />
                </div>
                {/* <br/> */}
                <br />
                <div className="drawerImagesContainer">
                  <div
                    id="multimediaFileDropBox"
                    className={
                      "drawerImagesContainerDiv " +
                      (fileRow.file != "" ? "hasFile" : "")
                    }
                    onDrop={(e) => {
                      e.preventDefault();
                      // UPDATE FILE
                      const files = e.dataTransfer.files;
                      const fileName = files[0].name;
                      console.log("fileName 1");
                      console.log(fileName);
                      handleFileInputChange(
                        fileRow.file_id,
                        files[0],
                        fileName,
                        fileRow.estado
                      );

                      const fileArray = Array.from(files).map((file: any) =>
                        URL.createObjectURL(file)
                      );

                      e.currentTarget.classList.remove("dropzone_active");
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.currentTarget.classList.add("dropzone_active");
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      e.currentTarget.classList.remove("dropzone_active");
                    }}
                  >
                    {fileRow.file && (
                      <span className="fileUploaded">
                        <AttachFileIcon />
                        {fileRow.name}
                      </span>
                    )}

                    <label
                      id="dropFileDiv"
                      className={fileRow.file && "showOnlyOnHover"}
                    >
                      <IconButton
                        aria-label="delete"
                        size="small"
                        className="addFile_button"
                        component="span"
                      >
                        <FileUploadIcon />

                        <input
                          type="file"
                          multiple
                          hidden
                          accept=".pdf"
                          onChange={(e) => {
                            const files = e.target.files;
                            if (files) {
                              //get file name
                              const fileName = files[0].name;
                              // console.log("fileName 2")
                              // console.log(fileName)
                              // UPDATE FILE
                              handleFileInputChange(
                                fileRow.file_id,
                                files[0],
                                fileName,
                                fileRow.estado
                              );
                              const fileArray = Array.from(files).map(
                                (file: any) => URL.createObjectURL(file)
                              );
                            }
                          }}
                        />
                      </IconButton>
                      Arrastra aquí un archivo o da click para subirlo.
                    </label>
                  </div>
                  {fileRow.file != "" && (
                    <Button
                      onClick={() => borrarPorIdPdf(fileRow.file_id)}
                      variant="contained"
                      className="button_1"
                    >
                      Borrar
                    </Button>
                  )}
                </div>
              </div>
            ))}

          <>
            <br></br>
            <br></br>
            {/* <br></br> */}
            <Button onClick={add_file} variant="contained" className="button_1">
              Agregar PDF
            </Button>
          </>
          <div className="buttons">
            <Button
              onClick={() => navigate(-1)}
              variant="outlined"
              className="button_2"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                if (!checkForm()) {
                  handleSubmit();
                }
              }}
              variant="contained"
              className="button_1"
            >
              Guardar
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
});
